import React, {useEffect, useState} from "react";
import {styled, useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import LocaleChanger from '../LocaleChanger';
import MainMenu from "../Menu/main/MobileMainMenu";
import Link from "next/link";
import {SessionContext, SessionContextInterface} from "../../hooks/SessionHook/SessionContext";
import {theme} from "../Theme/Theme";
import Stack from "@mui/material/Stack";
import {useRouter} from "next/router";
import {EPage} from "../../helpers/constants";
import {facade} from "../../helpers/analytic/AnalyticFacade";
import {MenuPageEvent} from "../../helpers/analytic/event/MenuPageEvent";
import InfoIcon from "@mui/icons-material/Info";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import StarIcon from "@mui/icons-material/Star";
import CollectionsIcon from "@mui/icons-material/Collections";
import PersonIcon from "@mui/icons-material/Person";
import LoginIcon from "@mui/icons-material/Login";
import AppBar_ProfileButton from "./AppBar_ProfileButton";
import AppBarBackground from "./AppBarBackground";
import Image from "next/image";
import {ClientEventAPI, ClientTournamentAPI} from '@trailblazer-game/leaderboards-sdk';
import {useTwitchStore} from "../../stores/twitch/twitchStore";
import LiveTvIcon from '@mui/icons-material/LiveTv';
import {active, EDIcon, EDIconSize, EDTypography, error as edError, grey, spacing} from "@trailblazer-game/ed-webkit";
import {error, log} from "../../helpers/utils/log";
import AppBar_DropdownMenu from "./AppBar_DropdownMenu";
import {ITournamentConfiguration} from "../../helpers/interfaces/tournament";
import {IEventConfig} from "../../helpers/interfaces/event";
import {NotionMenuGroup, NotionSlug} from "../../helpers/notion/notion";
import Footer_NotionLinks from "../Footer/Footer_NotionLinks";

const clientTournamentAPI = new ClientTournamentAPI();
const clientEventAPI = new ClientEventAPI();

export interface IMenuOption {
  id: string,
  label: string,
  icon?: React.ReactNode,
  active?: boolean,
  link?: string,
  content?: React.ReactNode;
  className?: string;
  items?: Partial<IMenuOption>[]
}

function AppBarTop(props: any) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
  const {hasSession} = React.useContext(SessionContext) as SessionContextInterface;
  const [tournamentConfig, setTournamentConfig] = useState<ITournamentConfiguration | null>(null);
  const [events, setEvents] = useState<Record<string, IEventConfig>>({});
  const [slugs, setSlugs] = useState<NotionSlug[]>([]);

  const showMenuText = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    getTournamentKey();
    getEvents();
    fetchSlugs();
  }, []);

  async function fetchSlugs() {
    const response = await fetch('/slugs.json');
    const jsonData = await response.json();
    setSlugs(jsonData);
  }

  const getTournamentKey = async () => {
    const result = await clientTournamentAPI.getConfig();

    if (!result.success) {
      error("AppBarTop", "Cannot load default tournament configuration:", result.reason)
    }

    const {config} = result;

    if (config) {
      setTournamentConfig(config);
    } else {
      error("AppBarTop", "Cannot load default tournament configuration, key is empty.")
    }
  }

  const getEvents = async () => {
    const {success, configs, reason} = await clientEventAPI.getAllConfigs();

    if (!success) {
      error("AppBarTop", "Cannot load events:", reason)
    }

    if (configs) {
      const entries = Object.entries(configs);
      const filteredConfig = entries
        .filter(([_, eventConfig]) =>
          (eventConfig as IEventConfig).showInMenu !== false
        )
        .sort((entryA, entryB) => (entryB[1].startTimestamp ?? 0) - (entryA[1].startTimestamp ?? 0))
        .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});

      setEvents(filteredConfig);
    } else {
      error("AppBarTop", "Cannot load events.")
    }
  }

  const DEV = false;
  const {t} = useTranslation();

  const openCommMenu = Boolean(anchorEl);
  const router = useRouter();

  const bHasSession = hasSession();

  const {streaming} = useTwitchStore();

  const byMenuOrder = (a: NotionSlug, b: NotionSlug) => (a.menu_order - b.menu_order);
  const getNotionLinksByGroup = (menuGroup: NotionMenuGroup) => slugs.filter(s => s.menu_group == menuGroup).sort(byMenuOrder);
  const renderDesktopNotionLinks = (menuGroup: NotionMenuGroup) => {
    const filteredSlugs = getNotionLinksByGroup(menuGroup);
    return filteredSlugs.length ? <Footer_NotionLinks slugs={filteredSlugs}/> : <></>;
  }

  //
  const menuOpts: IMenuOption[] = [
    {
      id: "about_us",
      label: t("menu.about_us"),
      icon: <InfoIcon/>,
      active: true,
      items: [
        {
          label: t("menu.gaming_info"),
          link: `/${EPage.ABOUT}`
        },
        {
          label: t("menu.community"),
          link: `/${EPage.COMMUNITY}`
        },
        {
          label: t("menu.news"),
          link: `/${EPage.NEWS}`
        },
        {
          label: t("menu.trailblazerGames"),
          link: `https://www.trailblazer.games/`
        }
      ]
    },
    {
      id: "marketplace",
      label: t("menu.marketplace"),
      icon: <LocalGroceryStoreIcon/>,
      active: false,
      link: "https://magiceden.io/marketplace/eternal_dragons_genesis"
    },
    {
      id: "ranks",
      label: t("menu.ranking"),
      icon: <StarIcon/>,
      link: `/${EPage.RANKING}`,
      active: true
    },
    {
      id: "league",
      label: t("menu.league"),
      icon: <StarIcon/>,
      active: true,
      items: [
        {
          label: t("menu.league_solo"),
          link: `/${EPage.LEAGUE_SOLO}`
        },
        {
          label: t("menu.league_guild"),
          link: `/${EPage.LEAGUE_GUILD}`
        }
      ]
    },
    {
      id: "tournament",
      label: t("menu.tournament"),
      icon: <StarIcon/>,
      active: true,
      items: [
        {
          label: tournamentConfig?.name || "",
          link: `/${EPage.TOURNAMENT}/${tournamentConfig?.key}`
        },
        {
          label: "Clash of Dragons: Mobile Tournament 3",
          link: `/${EPage.TOURNAMENT}/cdmt3`
        },
        {
          label: "Clash of Dragons: Mobile Tournament 2",
          link: `/${EPage.TOURNAMENT}/cdmt2`
        },
        {
          label: "Clash of Dragons: Mobile Tournament",
          link: `/${EPage.TOURNAMENT}/cdmt`
        },
        {
          label: "Clash of Dragons: Hamburg",
          link: `/${EPage.TOURNAMENT}/codh`
        },
        {
          label: "Season 2: Guild Tournament",
          link: `/${EPage.TOURNAMENT}/agt`
        },
        {
          label: "Season 1: Tournament",
          link: `/${EPage.TOURNAMENT}/ats1`
        }
      ]
    },
    {
      id: "questMode",
      label: t("menu.questMode"),
      icon: <StarIcon/>,
      active: true,
      items: Object.entries(events).map((entry, index) => ({
        label: entry[1]?.name || "",
        link: `/${EPage.QUESTMODE}/${entry[0]}`
      }))
    },
    {
      id: "watch_now",
      label: t("menu.watchNow"),
      icon: <LoginIcon/>,
      link: `/${EPage.WATCHNOW}`,
      active: true,
      content: streaming ? <Stack className={"live-dot"} direction={"row"} alignItems={"center"}
                                  justifyContent={"center"} spacing={0.5}>
        <EDIcon icon={<LiveTvIcon/>} size={EDIconSize.Small}/>
        <EDTypography variant={"caption"}>LIVE</EDTypography>
      </Stack> : undefined
    },
    // {
    //   id: "download_app",
    //   label: t("menu.downloadApp"),
    //   icon: <LoginIcon/>,
    //   link: `/${EPage.DOWNLOAD}`,
    //   active: true,
    //   content: <EDTypography variant={"caption"} className={"new-tag"}>NEW</EDTypography>
    // },
    // {
    //   id: "saga",
    //   label: t("menu.saga"),
    //   icon: <LoginIcon/>,
    //   link: `/${EPage.SAGA}`,
    //   active: true,
    // },
    {
      id: "my_gallery",
      label: t("menu.gallery"),
      icon: <CollectionsIcon/>,
      link: `/${EPage.MY_GALLERY}`,
      active: bHasSession
    },
    {
      id: "profile",
      label: t("menu.profile"),
      icon: <PersonIcon/>,
      link: `/${EPage.PROFILE}`,
      active: hasSession()
    },
    {
      id: "login",
      label: t("menu.login"),
      icon: <LoginIcon/>,
      link: `/${EPage.LOGIN}`,
      active: !hasSession()
    },
    ...getNotionLinksByGroup("Others").map((slug) => ({
      id: slug.slug || "",
      label: slug.title || "",
      link: slug.external ? slug.slug : `/${slug.slug}`,
      active: true
    }))
  ].filter((el: any) => el.active);

  //
  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMainMenuClose = (e: any, page: string) => {
    e.stopPropagation();

    handleMainMenuChange(e, page);
    setAnchorEl(undefined);
  };

  const handleMainMenuChange = (event: React.SyntheticEvent, newValue: string) => {
    facade.sendEvent(new MenuPageEvent(newValue));
  };

  const renderDesktopMenu = () => {
    return (
      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-start"}
             className={'links-container'}>

        <Link href={`/`} passHref>
          <a>
            <Box className={"ed-logo"} onClick={(e) => handleMainMenuChange(e, "homepage")}>
              <Image alt="Eternal Dragons Logo" src="/eternal_dragons_gold_dropshadow_1.webp" layout={"fill"}/>
            </Box>
          </a>
        </Link>

        <AppBar_DropdownMenu label={t("menu.about_us")}>
        <Link href={`/${EPage.ABOUT}`} passHref>
            <a>
              <Box
                className={"link"}
                onClick={(e) => handleMainMenuChange(e, "gaming_info")}>
                {t("menu.gaming_info")}
              </Box>
            </a>
          </Link>
          <Link href={`/${EPage.COMMUNITY}`} passHref>
            <a>
              <Box
                className={"link"}
                onClick={(e) => handleMainMenuChange(e, "community")}>
                {t("menu.community")}
              </Box>
            </a>
          </Link>
          <Link href={`/${EPage.NEWS}`} passHref>
            <a>
              <Box
                className={"link"}
                onClick={(e) => handleMainMenuChange(e, "news")}>
                {t("menu.news")}
              </Box>
            </a>
          </Link>
          <Link href={`https://www.trailblazer.games/`} passHref>
            <a>
              <Box
                className={"link"}
                onClick={(e) => handleMainMenuChange(e, "trailblazer games website")}>
                {t("menu.trailblazerGames")}
              </Box>
            </a>
          </Link>
          {renderDesktopNotionLinks("About")}
        </AppBar_DropdownMenu>

        <Stack direction={"row"} alignItems={"stretch"} justifyContent={"flex-start"} className={'nav-left'}>
          <Link href={`/${EPage.RANKING}`} passHref>
            <a>
              <Box className={router.pathname == "/ranking" ? "link active" : "link"}
                   onClick={(e) => handleMainMenuChange(e, "ranking")}>
                {t("menu.ranking")}
              </Box>
            </a>
          </Link>

          <AppBar_DropdownMenu label={t("menu.league")}>
            <Link href={`/${EPage.LEAGUE_SOLO}`} passHref>
              <a>
                <Box className={router.pathname == "/league/solo" ? "link active" : "link"}
                     onClick={(e) => handleMainMenuChange(e, "league/solo")}>
                  {t("menu.league_solo")}
                </Box>
              </a>
            </Link>
            <Link href={`/${EPage.LEAGUE_GUILD}`} passHref>
              <a>
                <Box className={router.pathname == "/league/guild" ? "link active" : "link"}
                     onClick={(e) => handleMainMenuChange(e, "league/guild")}>
                  {t("menu.league_guild")}
                </Box>
              </a>
            </Link>
          </AppBar_DropdownMenu>

          <AppBar_DropdownMenu label={t("menu.tournament")}>
            {
              tournamentConfig && <Link href={`/${EPage.TOURNAMENT}/${tournamentConfig.key}`} passHref>
                    <a>
                        <Box
                            className={router.asPath == `/${EPage.TOURNAMENT}/${tournamentConfig.key}` ? "link active" : "link"}
                            onClick={(e) => handleMainMenuChange(e, "tournament")}>
                          {tournamentConfig.name}
                        </Box>
                    </a>
                </Link>
            }
            <Link href={`/${EPage.TOURNAMENT}/cdmt3`} passHref>
              <a>
                <Box
                  className={router.asPath == `/${EPage.TOURNAMENT}/cdmt2` ? "link active" : "link"}
                  onClick={(e) => handleMainMenuChange(e, "tournament")}>
                  Clash of Dragons: Mobile Tournament 3
                </Box>
              </a>
            </Link>
            <Link href={`/${EPage.TOURNAMENT}/cdmt2`} passHref>
              <a>
                <Box
                  className={router.asPath == `/${EPage.TOURNAMENT}/cdmt2` ? "link active" : "link"}
                  onClick={(e) => handleMainMenuChange(e, "tournament")}>
                  Clash of Dragons: Mobile Tournament 2
                </Box>
              </a>
            </Link>
            <Link href={`/${EPage.TOURNAMENT}/cdmt`} passHref>
              <a>
                <Box
                  className={router.asPath == `/${EPage.TOURNAMENT}/cdmt` ? "link active" : "link"}
                  onClick={(e) => handleMainMenuChange(e, "tournament")}>
                  Clash of Dragons: Mobile Tournament
                </Box>
              </a>
            </Link>
            <Link href={`/${EPage.TOURNAMENT}/codh`} passHref>
              <a>
                <Box
                  className={router.asPath == `/${EPage.TOURNAMENT}/codh` ? "link active" : "link"}
                  onClick={(e) => handleMainMenuChange(e, "tournament")}>
                  Clash of Dragons: Hamburg
                </Box>
              </a>
            </Link>
            <Link href={`/${EPage.TOURNAMENT}/agt`} passHref>
              <a>
                <Box
                  className={router.asPath == `/${EPage.TOURNAMENT}/agt` ? "link active" : "link"}
                  onClick={(e) => handleMainMenuChange(e, "tournament")}>
                  Season 2: Guild Tournament
                </Box>
              </a>
            </Link>
            <Link href={`/${EPage.TOURNAMENT}/ats1`} passHref>
              <a>
                <Box
                  className={router.asPath == `/${EPage.TOURNAMENT}/ats1` ? "link active" : "link"}
                  onClick={(e) => handleMainMenuChange(e, "tournament")}>
                  Season 1: Tournament
                </Box>
              </a>
            </Link>
          </AppBar_DropdownMenu>
          <AppBar_DropdownMenu label={t("menu.questMode")}>
            {
              Object.entries(events).map((entry, index) => (
                <Link href={`/${EPage.QUESTMODE}/${entry[0]}`} passHref key={entry[0]}>
                  <a>
                    <Box
                      className={router.asPath == `/${EPage.QUESTMODE}/${entry[0]}` ? "link active" : "link"}
                      onClick={(e) => handleMainMenuChange(e, `Quest mode - ${entry[1]?.name || entry[0]}`)}>
                      {entry[1]?.name || ""}
                    </Box>
                  </a>
                </Link>
              ))
            }
          </AppBar_DropdownMenu>
          <Link href={`/${EPage.WATCHNOW}`} passHref>
            <a>
              <Box className={router.pathname == `/${EPage.WATCHNOW}` ? "link active" : "link"}
                   onClick={(e) => handleMainMenuChange(e, "watch now")}>
                {t("menu.watchNow")}
                {streaming && <Stack className={"live-dot"} direction={"row"} alignItems={"center"}
                                     justifyContent={"center"} spacing={0.5}>
                    <EDIcon icon={<LiveTvIcon/>} size={EDIconSize.Small}/>
                    <EDTypography variant={"caption"}>LIVE</EDTypography>
                </Stack>}
              </Box>
            </a>
          </Link>

          {/*<Link href={`/${EPage.DOWNLOAD}`} passHref>
            <a>
              <Box className={router.pathname == `/${EPage.DOWNLOAD}` ? "link active" : "link"}
                   onClick={(e) => handleMainMenuChange(e, "watch now")}>
                {t("menu.downloadApp")}
                <EDTypography variant={"caption"} className={"new-tag"}>NEW</EDTypography>
              </Box>
            </a>
          </Link>*/}

          {/*<Link href={`/${EPage.SAGA}`} passHref>
            <a>
              <Box className={router.pathname == `/${EPage.SAGA}` ? "link active" : "link"}
                onClick={(e) => handleMainMenuChange(e, "saga")}>
                {t("menu.saga")}
              </Box>
            </a>
          </Link>*/}
        </Stack>

        {DEV &&
            <>
                <Box onClick={(e) => handleMainMenuChange(e, "candy_machine")}
                     className="top-bar-right-menu-text">{t("menu.mint_nft")}</Box>
                <Box onClick={(e) => handleMainMenuChange(e, "inventory")}
                     className="top-bar-right-menu-text">{t("menu.inventory")}</Box>
                <Box onClick={(e) => handleMainMenuChange(e, "dashboard")}
                     className="top-bar-right-menu-text">{t("menu.dashboard")}</Box>
            </>
        }
      </Stack>
    );
  }

  const renderMobileMenu = () => {
    return (
      <Stack direction={'row'} className={'mobile-menu-container'} alignItems={"center"}
             justifyContent={"flex-start"} spacing={2}>
        <MenuIcon onClick={handleClick} sx={{
          color: "white",
          fontSize: "24px",
          cursor: "pointer",
          zIndex: 1000
        }}/>
        <Link href={`/`} passHref>
          <a>
            <Box className={"ed-logo mobile"} onClick={(e) => handleMainMenuChange(e, "homepage")}>
              <Image alt="Eternal Dragons Logo" src="/edlogomobile_1.webp" layout={"fill"}/>
            </Box>
          </a>
        </Link>
        <MainMenu menuOpts={menuOpts} anchorEl={anchorEl} open={openCommMenu}
                  handleClose={handleMainMenuClose}/>
      </Stack>
    )
  }

  //
  return (
    <Stack className={props.className} spacing={2} direction={"row"} id="appbar">
      <AppBarBackground/>

      {showMenuText && renderDesktopMenu()}

      {!showMenuText && renderMobileMenu()}

      <AppBar_ProfileButton handleMainMenuChange={handleMainMenuChange}/>

      {/* Change UI language */}
      {showMenuText && DEV &&
          <LocaleChanger siteLang={props.siteLang} mystyle={{margin: "0px 20px 0px 15px"}}/>}
    </Stack>
  );
}

const AppBarTopStyled = styled(AppBarTop)({
  boxShadow: 'none',
  maxWidth: `1920px`,
  zIndex: 5001, //theme.zIndex.drawer + 1,
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  padding: theme.spacing(2, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ['@media (min-width:1920px)']: {
    left: 'calc(50vw - 976px)',
  },
  'span, p, a': {
    letterSpacing: '0.1rem',
  },
  '.links-container': {
    height: '56px',
    width: '100%',
    position: 'relative',
    ['@media (max-width:1000px)']: {
      width: '100vw',
      maxWidth: '100vw',
    }
  },
  '.link': {
    textTransform: 'uppercase',
    color: theme.palette.grey['50'],
    fontWeight: '500',
    cursor: 'pointer',
    padding: theme.spacing(2),
    fontSize: '0.75rem',
    fontFamily: 'Figtree',
    letterSpacing: '0.1rem',
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    height: "21px !important",
    ".live-dot": {
      backgroundColor: active("error"),
      color: grey("50"),
      borderRadius: "4px",
      marginLeft: spacing(1),
      padding: theme.spacing(0.5, 1),
      "svg": {
        marginBottom: "4px"
      }
    },
    ".new-tag": {
      color: edError("main"),
      marginLeft: spacing(1)
    },
    '&:hover': {
      textShadow: `1px 0 0.5rem #ffe797`
    },
    '&.active': {
      color: '#ffea9a'
    }
  },
  '.mobile-menu-container': {
    width: '100%',
    padding: theme.spacing(2),
    marginLeft: 0
  },
  'a': {
    display: 'inline-flex',
    height: '100%'
  },
  '.ed-logo': {
    height: '56px',
    aspectRatio: '3.32',
    position: 'relative',
    cursor: "pointer",
    '&.mobile': {
      aspectRatio: '1',
      height: '48px'
    }
  },
  "#profile-button, #login-button": {
    background: theme.palette.getContrastText(theme.palette.secondary.dark),
    padding: "0",
    "& > .top-bar-right-menu-text": {
      padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
      color: theme.palette.secondary.dark
    },
    "&#login-button": {
      background: theme.palette.secondary.main,
      "& > .top-bar-right-menu-text": {
        padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
        color: theme.palette.getContrastText(theme.palette.secondary.main)
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    padding: 0
  }
});

export default AppBarTopStyled;