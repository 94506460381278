import React from "react";
import { styled } from '@mui/material/styles';
import Stack from "@mui/material/Stack";
import { EDButton, EDButtonType, EDTypography, edWebkitTheme } from "@trailblazer-game/ed-webkit";
import { useWallet } from "@solana/wallet-adapter-react";
import { IWalletAdapterContextInterface, WalletAdapterContext } from "./WalletAdapterContext";
import WalletInfo from "../../components/WalletInfo/WalletInfo";
import useMediaQuery from "@mui/material/useMediaQuery";

interface IWalletAdapterContextLinkWarningIdleProps {
    className?: string;
    onProceed: Function;
}

//
function WalletAdapterContextLinkWarningIdle(props: IWalletAdapterContextLinkWarningIdleProps) {
    const { connect, closeLinkedDialog } = React.useContext(WalletAdapterContext) as IWalletAdapterContextInterface;
    const isSmall = useMediaQuery(edWebkitTheme.breakpoints.down('sm'));

    const handleProceed = () => {
        if (props.onProceed) {
            props.onProceed();
        }
    }

    const handleClose = () => {
        closeLinkedDialog();
    }

    return <Stack spacing={4}>
        <Stack spacing={1}>
            <EDTypography variant={"body1"} align={"center"}>
                The wallet selected is not linked with your account.
            </EDTypography>
            <EDTypography variant={"body2"} align={"center"} sx={{ color: edWebkitTheme.palette.grey['600'] }}>
                <i>You can&#39;t access this content without linking this wallet.</i>
            </EDTypography>
        </Stack>
        <WalletInfo light={true} fullPublicKey={true}/>
        <Stack direction={isSmall ? "column" : "row"} spacing={2} alignItems={"center"} justifyContent={"center"}>
            <EDButton label={"Link"} type={EDButtonType.Primary} onClick={handleProceed}/>
            <EDButton label={"Change Wallet"} type={EDButtonType.Purple} onClick={() => connect()}/>
        </Stack>
    </Stack>
}

const WalletAdapterContextLinkWarningIdleStyled = styled(WalletAdapterContextLinkWarningIdle)(() => ({}));

// Export
export default WalletAdapterContextLinkWarningIdleStyled;