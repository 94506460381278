import { useState, useEffect, useRef } from 'react';

function useInfiniteScroll(fetchMore: () => Promise<any>) {
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        if(!scrollContainerRef.current) {
            return;
        }
        const observer = new IntersectionObserver((entries) => {
            const firstEntry = entries[0];
            if (firstEntry.isIntersecting && hasMore && !loading) {
                setLoading(true);
                fetchMore().then(() => {
                    setLoading(false);
                });
            }
        }, {threshold: 1});

        // @ts-ignore
        observer.observe(scrollContainerRef.current);
        return () => observer.disconnect();
    }, [fetchMore, hasMore, loading]);

    return [scrollContainerRef, loading, setHasMore];
}

export default useInfiniteScroll;