import { AnalyticEvent } from './AnalyticEvent';
import { fireBaseAnalyticAnonymous } from './firebase/FireBaseAnalyticAnonymous';
import { fireBaseAnalyticTBUid } from './firebase/FireBaseAnalyticTBUid';

export interface AnalyticProvider {
  sendEvent(event: AnalyticEvent): void;

  setUserAddress(address: string): void;

  sendRawEvent(name: string, parameterName: string, parameterValue: string): void;
}

//Does that work because we are servlerss?
//I mean we can have it as a singleton because we do one query after the other
class AnalyticFacade implements AnalyticProvider {

  providers: AnalyticProvider[];

  constructor() {

    this.providers = [];
    this.providers.push(fireBaseAnalyticTBUid);
    this.providers.push(fireBaseAnalyticAnonymous);

  }

  setUserAddress(address: string) {

  }

  sendEvent(event: AnalyticEvent) {
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].sendEvent(event);
    }
  }

  sendRawEvent(name: string, parameterName: string, parameterValue: string) {
    for (let i = 0; i < this.providers.length; i++) {
      this.providers[i].sendRawEvent(name, parameterName, parameterValue);
    }
  }
}

export const facade: AnalyticFacade = new AnalyticFacade();