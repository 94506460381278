import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { edWebkitTheme, error, grey, info, primary, scrollBar, spacing } from "@trailblazer-game/ed-webkit";
import Stack from "@mui/material/Stack";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box } from "@mui/material"
import useComponentVisible from "../../hooks/useComponentVisible";
import { API } from '@aws-amplify/api';
import MenuNotificationsList from "../NotificationsList/MenuNotificationsList";

API.configure({
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": `${process.env.NEXT_PUBLIC_FEED_API}/graphql`,
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_LAMBDA"
})

interface IAppBarNotificationCounterProps {
    className?: string;
}

function AppBarNotificationCounter(props: IAppBarNotificationCounterProps) {
    const [ hasNewNotifications, setHasNewNotifications ] = useState(false);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const onIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsComponentVisible(!isComponentVisible);
    }

    return <Stack className={`${props.className} ${isComponentVisible ? 'active' : ''}`} alignItems={"center"}
                  justifyContent={"center"} ref={ref}>
        <Stack alignItems={"center"} justifyContent={"center"} className={"notification-icon"} onClick={onIconClick}>
            <NotificationsIcon />
        </Stack>

        {!isComponentVisible && hasNewNotifications && <Box className={"notifications-dot"}/>}
        <Stack className={`notifications-menu${isComponentVisible ? " active" : ""} card-shadow`} spacing={1}>
            <MenuNotificationsList
                onNewNotificationChange={(newNotifications: boolean) => setHasNewNotifications(newNotifications)}
                onViewAll={() => setIsComponentVisible(false)}
            />
        </Stack>
    </Stack>
}

const AppBarNotificationCounterStyled = styled(AppBarNotificationCounter)({
    position: "relative",
    ".notification-icon": {
        width: '40px',
        height: '40px',
        backgroundColor: grey("800"),
        borderRadius: '40px',
        position: "relative",
        color: grey(50),
        "&:hover": {
            backgroundColor: grey("700"),
        },
        "&:active, &:focus": {
            ".notification-icon": {
                backgroundColor: grey("500")
            }
        }
    },
    "&.active": {
        ".notification-icon": {
            backgroundColor: primary("main")
        }
    },
    "svg": {
        width: '28px',
        height: '28px',
        color: grey(50)
    },
    ".notifications-dot": {
        position: "absolute",
        top: 0,
        right: 0,
        width: "12px",
        height: "12px",
        borderRadius: '12px',
        backgroundColor: error("main"),
        border: `2px solid ${grey(900)}`
    },
    ".notifications-menu": {
        position: "fixed",
        top: `calc(${edWebkitTheme.header.height.md} - 8px)`,
        left: `calc(50% + 440px)`,
        right: 0,
        backgroundColor: grey(900),
        width: '500px',
        height: '600px',
        overflow: 'hidden',
        margin: '0',
        padding: edWebkitTheme.spacing(1),
        display: 'none',
        borderRadius: '6px',
        ['@media (max-width:1920px)']: {
            right: 0,
            left: 'unset'
        },
        "&.active": {
            display: "flex"
        },
        ".toolbar": {
            padding: edWebkitTheme.spacing(1, 0)
        },
        "> .separator": {
            height: 'auto',
            width: '100%',
            border: `1px solid ${grey(700)}`
        }
    },
    [edWebkitTheme.breakpoints.down('sm')]: {
        ".notifications-menu": {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            paddingTop: edWebkitTheme.header.height.sm,
            zIndex: -1,
            transform: `none`,
            transformOrigin: '0 0',
            height: 'auto',
            width: '100%'
        }
    }
});

export default AppBarNotificationCounterStyled;