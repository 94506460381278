import { createStore } from "../createStore";
import { INotification } from "../../helpers/interfaces/notification";
import { API, graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../commands/graphql/messages/queries";
import * as mutations from "../../commands/graphql/messages/mutations";

API.configure({
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": `${process.env.NEXT_PUBLIC_FEED_API}/graphql`,
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_LAMBDA"
})

export interface INotificationStore {
    dataLoaded: boolean;
    notifications: INotification[];
    visibleNotifications: INotification[];
    hasNewNotifications: boolean;
    loadNotifications: (session: string) => void,
    addNotification: (notification: INotification) => void,
    setNotificationViewed: (notification: INotification, session: string) => void,
    setAllNotificationsViewed: (session: string) => void,
    deleteNotifications: (session: string) => void,
    loadMore: () => void
}

export const useNotificationsStore = createStore<INotificationStore>((get, set, setAsync) => ({
    dataLoaded: false,
    notifications: [],
    visibleNotifications: [],
    hasNewNotifications: false,
    loadNotifications: (session: string) => setAsync((store: INotificationStore) => _loadNotifications(store, session)),
    addNotification: (notification: INotification) => set((store: INotificationStore) => _addNotification(store, notification)),
    setNotificationViewed: (notification: INotification, session: string) => setAsync((store: INotificationStore) => _setNotificationViewed(store, notification, session)),
    setAllNotificationsViewed: (session: string) => setAsync((store: INotificationStore) => _setAllNotificationsViewed(store, session)),
    deleteNotifications: (session: string) => setAsync((store: INotificationStore) => _deleteNotifications(store, session)),
    loadMore: () => set((store: INotificationStore) => _loadMore(store))
})) as () => INotificationStore;

const _loadNotifications = async (store: INotificationStore, session: string) => {
    const res = await API.graphql<any>(graphqlOperation(queries.getMessages, {/*count: 50*/ }, session));

    if (res && res.data && res.data.getMessages) {
        let notifications = res.data.getMessages.messages.slice();
        notifications = notifications.sort((a: INotification, b: INotification) => a.createdAt > b.createdAt ? -1 : 1);
        let hasNewNotifications = notifications.find((m: INotification) => !m.viewed) != null;
        let visibleNotifications = [];

        for (let i = 0; i < Math.min(notifications.length, 10); ++i) {
            visibleNotifications.push(notifications[i]);
        }

        return { ...store, loading: false, notifications, visibleNotifications, hasNewNotifications, dataLoaded: true };
    }

    console.error(res);
    return { ...store, loading: false, dataLoaded: false };
}

const _addNotification = (store: INotificationStore, notification: INotification) => {
    return {
        ...store,
        notifications: [ notification, ...store.notifications ],
        visibleNotifications: [ notification!, ...store.visibleNotifications ],
        hasNewNotifications: true
    };
}

const _setNotificationViewed = async (store: INotificationStore, notification: INotification, session: string) => {
    const res = await API.graphql<any>(graphqlOperation(mutations.messageViewed, { msgUid: notification.msgUid }, session as string));
    if (res && res.data && res.data.messageViewed) {
        let notifications = store.notifications;
        const targetMessage = notifications.find((m: INotification) => m.msgUid == notification.msgUid)

        if (targetMessage) {
            targetMessage.viewed = true;
        }

        const hasNewNotifications = notifications.find((m: INotification) => !m.viewed) != null;

        return {
            ...store,
            notifications,
            hasNewNotifications
        };
    } else {
        console.warn("Something went wrong while marking the notification %o as viewed, reason: %o", notification.msgUid, res);
    }
}

const _setAllNotificationsViewed = async (store: INotificationStore, session: string) => {
    if (!store.notifications.length) {
        return store;
    }

    const unreadedNotifications = [ ...store.notifications ].filter(n => !n.viewed);

    if (!unreadedNotifications.length) {
        return store;
    }

    const allPromises = unreadedNotifications.map((notification) => API.graphql<any>(graphqlOperation(mutations.messageViewed, { msgUid: notification.msgUid }, session)));
    const results = await Promise.allSettled(allPromises);

    results.forEach((result, index) => {
        if (result.status == "fulfilled") {
            unreadedNotifications[index].viewed = true;
        } else {
            console.warn(result);
        }
    })

    return {
        ...store,
        hasNewNotifications: false
    }
}

const _deleteNotifications = async (store: INotificationStore, session: string) => {
    try {
        if (!store.notifications.length) {
            return store;
        }

        const allPromises = store.notifications.map((notification) => API.graphql<any>(graphqlOperation(mutations.deleteMessage, { msgUid: notification.msgUid }, session)));

        await Promise.allSettled(allPromises);

        return {
            ...store,
            notifications: [],
            visibleNotifications: [],
            hasNewNotifications: false
        }
    } catch (ex) {
        console.error(ex);
        return store;
    }
}

const _loadMore = (store: INotificationStore) => {
    const notifications = store.notifications;
    const visibleNotifications = store.visibleNotifications;

    if (visibleNotifications.length >= notifications.length) {
        return store;
    }

    const startIndex = visibleNotifications.length;
    const lastIndex = Math.min(notifications.length, visibleNotifications.length + 10);

    for (let i = startIndex; i < lastIndex; ++i) {
        visibleNotifications.push(notifications[i]);
    }

    return {
        ...store,
        visibleNotifications
    }
}