export const onAddMessage = `
    subscription OnAddMessage($tbUid: String!) {
        onAddMessage(tbUid: $tbUid) {
            tbUid
            msgUid
            msg
            viewed
            createdAt
            category
        }
    }
`;
