export const messageViewed = `
  mutation MessageViewed($msgUid: String!) {
    messageViewed(msgUid: $msgUid) {
      tbUid
      msgUid
      msg
      viewed
      createdAt
      category
    }
  }
`;

export const deleteMessage = `
    mutation DeleteMessage($msgUid: String!) {
        deleteMessage(msgUid: $msgUid) {
            tbUid
            msgUid
            msg
            viewed
            createdAt
            category
        }
    }
`;