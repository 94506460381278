
export abstract class AnalyticEvent {

    name: string;
    properties: {};

    constructor(name: string, properties: {}) {
        this.name = name;
        this.properties = properties;
    }

}