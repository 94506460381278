import { IInventoryDragon, IInventoryDescendantEgg, IInventoryMinion } from "./dragon";
import { IInventoryMedal } from "./medal";
import { SuperContentSymbols, SuperContentSymbolToName } from "./superContent";
import { IInventorySagaCard, IInventoryTicketPlayerCard } from "./tournament";
import { IInventoryTrophy } from "./trophy";
import { IInventoryOther } from "./other";

const IGNORED_PATHS_WELCOME = ['/profile', '/emailVerify'];
export type ClaimFormValue = {
    emailAddress: string;
    wallets: string[];
    isDataValid: boolean;
    confirmed: boolean;
};

export interface IClientData {
    gameFormSubmitted?: boolean;
    firstTimeExperience?: boolean;
    alphaAccess?: boolean;
    ToS01102023?: boolean;
    acceptBuyBack?: boolean;
    claimTosAccepted?: boolean;
    claimFormValue?: ClaimFormValue | null;
}

export interface ILogin {
    provider?: string
    providerId?: string
    metadata?: {
        email?: string;
        email_verified?: boolean;
        verified_email?: boolean;
    }
}

export interface IProfileData {
    client?: IClientData | null;
    readonly?: any | null
    logins?: ILogin[]
}


export class UserData {
    descendantEggs: IInventoryDescendantEgg[] = [];
    Genesis: IInventoryDragon[] = [];
    Minion: IInventoryMinion[] = [];
    ticketsPlayerCards: IInventoryTicketPlayerCard[] = [];
    sagaCards: IInventorySagaCard[] = [];
    Descendant: IInventoryDragon[] = [];
    profileData: IProfileData = {};
    Trophy: IInventoryTrophy[] = [];
    Medal: IInventoryMedal[] = [];
    Other: IInventoryOther[] = [];

    constructor(data: Partial<UserData> = {}) {
        this.descendantEggs = data.descendantEggs || [];
        this.Genesis = data.Genesis || [];
        this.Minion = data.Minion || [];
        this.ticketsPlayerCards = data.ticketsPlayerCards || [];
        this.sagaCards = data.sagaCards || [];
        this.Descendant = data.Descendant || [];
        this.profileData = data.profileData || { client: null, readonly: null };
        this.Trophy = data.Trophy || [];
        this.Medal = data.Medal || [];
        this.Other = data.Other || [];

        this.sort();
    }

    // Public methods

    public sort() {
        this.sortdescendantEggs();
    }

    public empty() {
        let bEmpty = true;

        bEmpty = bEmpty && (!this.Genesis || this.Genesis.length === 0);
        bEmpty = bEmpty && (!this.Descendant || this.Descendant.length === 0);
        bEmpty = bEmpty && (!this.descendantEggs || this.descendantEggs.length === 0);
        bEmpty = bEmpty && (!this.ticketsPlayerCards || this.ticketsPlayerCards.length === 0);
        bEmpty = bEmpty && (!this.sagaCards || this.sagaCards.length === 0);
        bEmpty = bEmpty && (!this.Minion || this.Minion.length === 0);
        bEmpty = bEmpty && (!this.Trophy || this.Trophy.length === 0);
        bEmpty = bEmpty && (!this.Medal || this.Medal.length === 0);
        bEmpty = bEmpty && (!this.Other || this.Other.length === 0);

        return bEmpty;
    }

    public hasDragons(): boolean {
        return (this.Genesis && this.Genesis.length > 0) || (this.Descendant && this.Descendant.length > 0);
    }
    
    public doesItemExist(symbol: string, id: string): boolean {
        // Check if the symbol is a key in SuperContentSymbolToName
        if (symbol in SuperContentSymbolToName) {
            // TypeScript now understands that symbol is a key of SuperContentSymbolToName
            const collectionName = SuperContentSymbolToName[symbol as keyof typeof SuperContentSymbolToName];
            return this[collectionName].find((el: any) => el.id === id) !== undefined;
        } else {
            // Handle the case where the symbol is not one of the predefined ones
            return this.Other.find((el: any) => el.id === id) !== undefined;
        }
    }
    

    public hasLinkedWallet(): boolean {
        if (!this.profileData) {
            return false;
        }

        if (!this.profileData.logins || this.profileData.logins.length <= 0) {
            return false;
        }


        return this.profileData.logins.find(l => l.provider == 'wallet') != null;
    }

    public hasLinkedWalletWithPublicKey(publicKey: string | undefined): boolean {
        if (!publicKey) {
            return false;
        }

        if (!this.profileData) {
            return false;
        }

        if (!this.profileData.logins || this.profileData.logins.length <= 0) {
            return false;
        }

        return this.profileData.logins.find(l => l.provider == 'wallet' && l.providerId == publicKey) != null;
    }

    public walletLogins(): ILogin[] {
        if (!this.profileData) {
            return [];
        }

        if (!this.profileData.logins || this.profileData.logins.length <= 0) {
            return [];
        }

        return this.profileData.logins.filter(l => l.provider == 'wallet');
    }


    public needToS(path: string): boolean {

        //  console.log("needToS", path)

        if (["/tos"].indexOf(path) > -1) {
            return false;
        }

        if (!this.profileData) {
            return false;
        }

        if (!this.profileData.client) {
            return false;
        }

        return !this.profileData.client.ToS01102023;
    }

    public needWelcome(path: string): boolean {
        if (IGNORED_PATHS_WELCOME.indexOf(path) > -1) {
            return false;
        }

        if (!this.profileData) {
            return false;
        }

        if (!this.profileData.client) {
            return false;
        }

        return !this.profileData.client.firstTimeExperience;
    }

    public getEmail(): { email: string | null, emailVerified: boolean } {
        let email = null;
        let emailVerified = false;

        this.profileData.logins?.forEach((login, index) => {
            if (login.provider == "google" && login.metadata) {
                email = login.metadata.email;
                emailVerified = login.metadata.verified_email || false;

                return;
            }

            if (login.provider == "emailPassword" && login.metadata) {
                email = login.metadata.email;
                emailVerified = login.metadata.email_verified || false;

                return;
            }

            if (login.provider == "emailLink" && login.metadata) {
                email = login.metadata.email;
                emailVerified = login.metadata.email_verified || false;

                return;
            }
        });

        return { email, emailVerified };
    }

    // Public Methods

    private sortdescendantEggs() {
        this.descendantEggs.sort((eggA, eggB) => this.sortAscending<string>(eggA.name, eggB.name))
    }

    private sortAscending<T>(propA: T, propB: T) {
        if (propA > propB) return -1;
        if (propA < propB) return 1;
        return 0;
    }

    private sortDescending<T>(propA: T, propB: T) {
        if (propA > propB) return 1;
        if (propA < propB) return -1;
        return 0;
    }
}