import * as React from 'react';
import { memo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import Link from "next/link";
import { NotionSlug } from "../../helpers/notion/notion";

interface IFooterNotionLinksProps {
  className?: string;
  slugs?: NotionSlug[];
}

// Component
const FooterNotionLinks = memo(({ className, slugs }: IFooterNotionLinksProps) => {
  if (!slugs) {
    return <></>;
  }

  return <Stack className={className} spacing={2}>
    {slugs.map((slug, index) => {
      if (slug.hide_in_production && process.env.NEXT_PUBLIC_ENV === "production") {
        return null;
      }

      if (slug.external) {
        return <a href={`${slug.slug}`} key={index} target={"_blank"} rel={"noreferrer noopener"}>
          <Box className={`link ${slug.hide_in_production ? "dev-only" : ""}`}
               title={slug.hide_in_production ? "Visible only on staging" : ""}>
            {slug.title}
          </Box>
        </a>;
      } else {
        return <Link href={`${slug.slug}`} passHref key={index}>
          <a>
            <Box className={`link ${slug.hide_in_production ? "dev-only" : ""}`}
                 title={slug.hide_in_production ? "Visible only on staging" : ""}>
              {slug.title}
            </Box>
          </a>
        </Link>
      }
    })}
  </Stack>
})

FooterNotionLinks.displayName = "FooterNotionLinks";

// Default style!
const FooterNotionLinksStyled = styled(FooterNotionLinks)({
  '.link': {
    '&.dev-only': {
      opacity: "0.5"
    }
  }
});

// Export
export default FooterNotionLinksStyled;
