import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties, } from "firebase/analytics";
import { AnalyticEvent } from '../AnalyticEvent';
import { AnalyticProvider } from '../AnalyticFacade';
import * as Sentry from "@sentry/browser";
import { getTBUid } from '../../../hooks/SessionHook/SessionContext';
import { log } from "../../utils/log";


const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_TBUID_ANALYTIC_API,
  authDomain: process.env.NEXT_PUBLIC_TBUID_ANALYTIC_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_TBUID_ANALYTIC_PROJECTID,
  storageBucket: process.env.NEXT_PUBLIC_TBUID_ANALYTIC_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_TBUID_ANALYTIC_SENDERID,
  appId: process.env.NEXT_PUBLIC_TBUID_ANALYTIC_APPID,
  measurementId: process.env.NEXT_PUBLIC_TBUID_ANALYTIC_MEASUREMENT
};

class FireBaseAnalyticTBUid implements AnalyticProvider {

  analytics: any;

  app: any;

  canSend: boolean;

  id: string | undefined;

  constructor() {

    this.app = initializeApp(firebaseConfig, "[logged]");

    let tbUid = getTBUid();
    this.canSend = false;

    if (tbUid) {
      this.canSend = true;
    }
  }

  getFirebaseAnalytics() {

    return getAnalytics(this.app);
  }

  setUserGender(gender: string): void {

    if (this.app.name && typeof window !== 'undefined' && !this.analytics) {
      this.analytics = getAnalytics(this.app);
    }

    setUserProperties(this.analytics, { "tbGender": gender });
  }

  setPlayerType(player: string): void {

    if (this.app.name && typeof window !== 'undefined' && !this.analytics) {
      this.analytics = getAnalytics(this.app);
    }

    setUserProperties(this.analytics, { "playerType": player });
  }

  setYearOfBirth(dob: string): void {

    if (this.app.name && typeof window !== 'undefined' && !this.analytics) {
      this.analytics = getAnalytics(this.app);
    }

    setUserProperties(this.analytics, { "yearOfBirth": dob });
  }


  setUserAddress(address: string): void {

    Sentry.setUser({ id: address });

    console.log("Set TbUid address", address)
    if (this.app.name && typeof window !== 'undefined' && !this.analytics) {
      this.analytics = getAnalytics(this.app);
    }

    this.canSend = true;
    // setUserId(this.analytics, address);
    this.id = address;
  }

  sendEvent(event: AnalyticEvent) {
    if (!this.canSend) {
      return;
    }

    log("FireBaseAnalyticTBUid", "Send anonymous event", {
      ...event.properties,
      'tbUid': this.id
    });

    if (this.app.name && typeof window !== 'undefined' && !this.analytics) {
      this.analytics = getAnalytics(this.app);
    }

    logEvent(this.analytics, event.name, {
      ...event.properties,
      'tbUid': this.id
    });
  }

  sendRawEvent(name: string, parameterName: string, parameterValue: string) {

    console.log("SEND RAW EVENT", name)

    if (this.canSend) {
      if (this.app.name && typeof window !== 'undefined' && !this.analytics) {
        this.analytics = getAnalytics(this.app);
      }

      if (parameterName != undefined && parameterValue != undefined)
        logEvent(this.analytics, name, {
          parameterName: parameterValue,
          'tbUid': this.id
        });
      else
        logEvent(this.analytics, name, {
          'tbUid': this.id
        });
    }
  }
}

export let fireBaseAnalyticTBUid = new FireBaseAnalyticTBUid();


