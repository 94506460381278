import React, { ForwardedRef, forwardRef, useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import { EDAlert, EDTypography, grey, info, spacing } from "@trailblazer-game/ed-webkit";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material"
import { INotification } from "../../helpers/interfaces/notification";
import { getHumanTimeDifference } from "../../helpers/dates";
import ArticleIcon from '@mui/icons-material/Article';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import EggIcon from '@mui/icons-material/Egg';
import PaidIcon from '@mui/icons-material/Paid';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import copy from "copy-to-clipboard";
import { EPage } from "../../helpers/constants";
import { useNotificationsStore } from "../../stores/notifications/notificationsStore";
import { SessionContext, SessionContextInterface } from "../../hooks/SessionHook/SessionContext";
import { useRouter } from "next/router";

interface INotificationListItemProps {
    className?: string;
    notification: INotification;
    ref?: any;
}

const getIconByCategory = (notification: INotification) => {
    switch (notification.category) {
        case "news":
            return <ArticleIcon/>;
        case "invitations":
            return <VideogameAssetIcon/>;
        case "trading":
            return <CurrencyExchangeIcon/>;
        case "breeding":
            return <EggIcon/>;
        case "payments":
            return <PaidIcon/>;
        case "other":
        default:
            return <NotificationsActiveIcon/>;
    }
}

const NotificationListItem = forwardRef((props: INotificationListItemProps, ref: ForwardedRef<any> | undefined) => {
    const { notification } = props;
    const { session } = useContext(SessionContext) as SessionContextInterface;
    const { setNotificationViewed } = useNotificationsStore();
    const [alertText, setAlertText] = useState("");
    const router = useRouter();

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        // if (event) {
        //     event.preventDefault();
        //     event.stopPropagation();
        // }

        if (!notification.viewed) {
            onNotificationViewed(notification, event);
        }

        if (notification.category == "game") {
            const pattern = /[tpr]-\w{5,6}/;
            const match = notification.msg.match(pattern);

            setAlertText("Room ID copied.");

            if (match) {
                const roomId = match[0];
                copy(roomId);
            }

            router.push(`/${EPage.GAME}`);
        }
    }

    const onNotificationViewed = async (notification: INotification | undefined, e: any = null) => {
        if (!notification || !notification.msgUid) {
            return;
        }

        await setNotificationViewed(notification, session as string);
    }

    return <Box className={`${props.className} ${notification.viewed ? '' : ' not-viewed'}`}
                sx={{ width: '100%' }}
                ref={ref}
                onClick={handleClick}
    >
        <Box className={"icon"}>
            {getIconByCategory(notification)}
        </Box>
        <Stack alignItems={"flex-start"} justifyContent={"center"} spacing={1} sx={{ width: '100%' }}>
            <EDTypography variant={"body2"}
                          dangerouslySetInnerHTML={{ __html: notification.msg || "" }}
                          sx={{ userSelect: 'text' }}></EDTypography>
            <EDTypography variant={"caption"} sx={{ color: grey(400) }}>
                {getHumanTimeDifference(notification.createdAt || 0)}
            </EDTypography>
        </Stack>
        {!notification.viewed && <Box className={"blue-dot"}/>}
        {alertText && <EDAlert open={true} onClose={() => setAlertText("")} message={alertText} autoHideDuration={1000}/>}
    </Box>
});

NotificationListItem.displayName = "NotificationListItem";

const NotificationListItemStyled = styled(NotificationListItem)({
    color: grey(400),
    padding: spacing(1),
    display: 'grid',
    gridTemplateColumns: '64px 10fr 64px',
    gridTemplateRows: '100%',
    placeContent: 'center',
    placeItems: 'center',
    columnGap: spacing(1),
    backgroundColor: `rgba(33, 33, 33, 0.8)`,
    cursor: 'pointer',
    borderRadius: '6px',
    "&.not-viewed": {
        cursor: 'pointer',
        backgroundColor: `rgba(33, 33, 33, 0.8)`,
        color: grey(50)
    },
    "&:hover": {
        backgroundColor: 'rgba(33, 33, 33, 0.8)',
        "&.not-viewed": {
            backgroundColor: `rgba(33, 33, 33, 0.5)`
        }
    },
    ".icon": {
        width: '32px',
        height: '32px',
        "svg": {
            width: '32px',
            height: '32px',
        }
    },
    ".blue-dot": {
        backgroundColor: info("main"),
        width: '8px',
        height: '8px',
        borderRadius: '8px'
    }
});

export default NotificationListItemStyled;