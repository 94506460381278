import React, { useContext, useEffect, useState } from "react";
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useRouter } from "next/router";
import { fireBaseAnalyticAnonymous } from "../../helpers/analytic/firebase/FireBaseAnalyticAnonymous";

export const DOMAIN = process.env.NEXT_PUBLIC_DOMAIN_URL || 'eternaldragons.com';

export interface AnonymousIdContextInterface {
    idfa: string | null | undefined | boolean;
    setID: (session: string) => void;
    hasID: () => boolean;
    getID: () => string | undefined;
}

export const AnonymousID = React.createContext<AnonymousIdContextInterface | null>(null);

export interface props {
    session: string;
    children: any
}

export const AnonymousIdProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [idfa, setID] = useState(getIDFASession());


    useEffect(()=>{
    
      if(idfa)
        fireBaseAnalyticAnonymous.setUserAddress(idfa);
        

    },[idfa]);

    const hasID = (session: string | null | undefined | boolean) => {
        return session !== undefined
    }

    const getID = (session: string | null | undefined | boolean): string => {

        let idfa = getIDFASession();

        return idfa;
    }

    return (
        <AnonymousID.Provider value={{ idfa, setID, hasID: () => { return hasID(idfa) }, getID: () => { return getID(idfa) } }}>
            {children}
        </AnonymousID.Provider>
    )
}

export const useIDFA = () => React.useContext(AnonymousID);

function generateID():string {

   return `${Date.now()}_${(Math.random() + 1).toString(36).substring(2)}`;
}

export function getIDFASession(): string {


    let idfa = getCookie("ed_anonymous_id", { domain: DOMAIN })?.toString();

    if (idfa == null) {
        idfa = `ED_ANO_${generateID()}`;
    }

    setCookie(
        "ed_anonymous_id",
        idfa,
        {
            maxAge: 365 * 24 * 60 * 60,
            path: "/",
            domain: DOMAIN,
            secure: true
        });


    return idfa

}


export interface IWithEDAnonymousIDProps {
    children?: React.ReactNode;
    anonymousId: string | undefined;
}

export function withEDAnonymousID<T extends IWithEDAnonymousIDProps = IWithEDAnonymousIDProps>(Component: React.ComponentType<T>) {

    const WithIDFAComponent = (props: Omit<T, keyof IWithEDAnonymousIDProps>) => {

        const { getID } = React.useContext(AnonymousID) as AnonymousIdContextInterface;

        let idfa = getID();

        const router = useRouter();

        const authProps: IWithEDAnonymousIDProps = { anonymousId: idfa }

        return <Component {...authProps} {...(props as T)} />;
    };

    return WithIDFAComponent;
};





