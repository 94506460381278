const isProductionBlue = () => {
    const production = isProduction();

    if(!production) {
        return false;
    }

    return process.env.NEXT_PUBLIC_WEBAPP_URL!.indexOf("green") < 0;
}

const isProduction = () => {
    return process.env.NEXT_PUBLIC_ENV === "production";
}

export const PRODUCTION_BLUE = isProductionBlue();
export const PRODUCTION = isProduction();
export const STAGING = !PRODUCTION;