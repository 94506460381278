import { error, log, warn } from "../../helpers/utils/log";
import { Promise } from "es6-promise";

export interface ITwitchAccessToken {
    access_token: string;
    expires_in: number;
    token_type: string;
}

export interface ITwitchStreamData {
    data: ITwitchStream[];
    pagination: {
        cursor?: string;
    };
}

interface ITwitchStream {
    id: string;
    user_id: string;
    user_name: string;
    game_id: string;
    type: string;
    title: string;
    viewer_count: number;
    started_at: string;
    language: string;
    thumbnail_url: string;
    tag_ids?: string[];
}

export interface ITwitchChannelInfo {
    broadcaster_id: string;
    broadcaster_login: string;
    broadcaster_name: string;
    broadcaster_language: string;
    game_id: string;
    game_name: string;
    title: string;
    delay: number;
    tags: string[];
}

export interface ITwitchFollowersResponse {
    total: number;
    data: ITwitchFollower[];
    pagination: {
        cursor: string;
    }
}

export interface ITwitchFollower {
    user_id: string;
    user_name: string;
    user_login: string;
    followed_at: string;
}

interface ITwitchUserResponse {
    data: ITwitchUser[];
}

export interface ITwitchUser {
    id: string;
    login: string;
    display_name: string;
    profile_image_url: string;
    offline_image_url: string;
    view_count: number;
}

export class EDTwitchAPI {
    private static instance: EDTwitchAPI;
    private accessToken: any;

    private constructor() {
        log("EDTwitchAPI", "Constructor called.");
        // Private constructor to prevent instantiation from outside the class
    }

    public static getInstance(): EDTwitchAPI {
        if (!EDTwitchAPI.instance) {
            EDTwitchAPI.instance = new EDTwitchAPI();
        }

        return EDTwitchAPI.instance;
    }

    public async getStreamData(channelId: string, reFetch: boolean = false): globalThis.Promise<ITwitchStreamData | null> {
        try {
            if (this.isAccessTokenExpired(this.accessToken)) {
                this.accessToken = await this.getAccessToken();
            }

            const result = await fetch(`https://api.twitch.tv/helix/streams?user_id=${channelId}`, {
                headers: {
                    'Authorization': `Bearer ${this.accessToken.access_token}`,
                    'Client-Id': process.env.NEXT_PUBLIC_TWITCH_CLIENT_ID as any
                }
            });

            let data = await result.json();

            if (!reFetch && data.status == 401) {
                // try to refresh the token and load the data again
                this.accessToken = await this.getAccessToken();
                warn("EDTwitchAPI", "Trying to get another accessToken: ", this.accessToken);
                data = await this.getStreamData(channelId, reFetch);
            }

            log("EDTwitchAPI", "Streaming data: ", data);

            return data as ITwitchStreamData;
        } catch (e) {
            error("EDTwitchAPI", "Error getting stream data: ", e);
            return null;
        }
    }

    public async getAccessToken(): globalThis.Promise<ITwitchAccessToken | null> {
        try {
            const accessTokenResponse = await fetch('https://id.twitch.tv/oauth2/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    client_id: process.env.NEXT_PUBLIC_TWITCH_CLIENT_ID,
                    client_secret: process.env.NEXT_PUBLIC_TWITCH_CLIENT_SECRET,
                    grant_type: 'client_credentials'
                })
            });

            const accessTokenData = await accessTokenResponse.json();
            log("EDTwitchAPI", "Bearer token: ", accessTokenData);

            return accessTokenData as ITwitchAccessToken;
        } catch (e) {
            error("EDTwitchAPI", "Error getting access token: ", e);
            return null;
        }
    }

    public isAccessTokenExpired(token: ITwitchAccessToken): boolean {
        if (!token) {
            return true;
        }

        const expirationDate = new Date(Date.now() + token.expires_in * 1000);
        const currentTime = new Date();
        const bExpired = expirationDate <= currentTime;

        return bExpired;
    }

    public async getChannelFollowersCount(channelId: string): globalThis.Promise<number> {
        const {total} = await this.getChannelFollowers(channelId);

        return total;
    }

    public async getChannelFollowers(channelId: string): globalThis.Promise<ITwitchFollowersResponse> {
        if (this.isAccessTokenExpired(this.accessToken)) {
            this.accessToken = await this.getAccessToken();
        }

        const result = await fetch(`https://api.twitch.tv/helix/channels/followers?broadcaster_id=${channelId}`, {
            headers: {
                'Authorization': `Bearer ${this.accessToken.access_token}`,
                'Client-Id': process.env.NEXT_PUBLIC_TWITCH_CLIENT_ID as any
            }
        });

        return await result.json();
    }

    public async getChannelInformations(channelId: string): globalThis.Promise<ITwitchChannelInfo> {
        if (this.isAccessTokenExpired(this.accessToken)) {
            this.accessToken = await this.getAccessToken();
        }

        const result = await fetch(`https://api.twitch.tv/helix/channels?broadcaster_id=${channelId}`, {
            headers: {
                'Authorization': `Bearer ${this.accessToken.access_token}`,
                'Client-Id': process.env.NEXT_PUBLIC_TWITCH_CLIENT_ID as any
            }
        });

        return await result.json();
    }

    public async getUserData(userId: string): globalThis.Promise<ITwitchUser | null> {
        if (this.isAccessTokenExpired(this.accessToken)) {
            this.accessToken = await this.getAccessToken();
        }

        const result = await fetch(`https://api.twitch.tv/helix/users?id=${userId}`, {
            headers: {
                'Authorization': `Bearer ${this.accessToken.access_token}`,
                'Client-Id': process.env.NEXT_PUBLIC_TWITCH_CLIENT_ID as any
            }
        });

        const { data } = await result.json() as ITwitchUserResponse;
        log("EDTwitchAPI", "User data: ", data);
        return data ? data[0] : null;
    }
}