import React, { useReducer } from "react";
import { styled } from '@mui/material/styles';
import Stack from "@mui/material/Stack";
import { EDButton, EDButtonType, EDTypography } from "@trailblazer-game/ed-webkit";
import WalletAdapter from "../../components/WalletAdapter/WalletAdapter";
import { IWalletAdapterContextInterface, WalletAdapterContext } from "./WalletAdapterContext";

interface IWalletAdapterContextLinkWarningLinkingProps {
    className?: string;
    onClose?: Function;
}
//
function WalletAdapterContextLinkWarningLinking(props: IWalletAdapterContextLinkWarningLinkingProps) {

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    }
    return <Stack spacing={4}>
        <EDTypography variant={"body1"}>
            Please link your wallet:
        </EDTypography>
        <WalletAdapter />
        <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"center"}>
            <EDButton label={"Close"} type={EDButtonType.Error} onClick={handleClose}/>
        </Stack>
    </Stack>
}

const WalletAdapterContextLinkWarningLinkingStyled = styled(WalletAdapterContextLinkWarningLinking)(() => ({}));

// Export
export default WalletAdapterContextLinkWarningLinkingStyled;