import React from 'react';
import './../i18n/config';
import TwitchStoreController from "../stores/twitch/TwitchStoreController";


/**
 * Handles all store controllers
 */
function Stores() {
    return <>
        <TwitchStoreController/>
    </>;
}

export default Stores;
