import axios from 'axios';
import { APILoginResult, APIResult, IdentityResult } from '../APIResult';


export async function getIdentity(token: string): Promise<IdentityResult> {

    try {

        let res = await axios.post(`${process.env.NEXT_PUBLIC_IDENTITY}/identity/get`, {},
            {
                headers: {
                    Authorization: token
                }
            });

        console.log("RES IDENTIT", res)
        if (res.data) {
            // created
            return res.data
        }

        return { status: -1 }

    } catch (error: any) {

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log('Error', error.response.data);
            console.log('Error', error.response.status);
            console.log('Error', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log('Error', error.config);

        return { status: -1 }
    };


}