import React from "react";
import { styled } from '@mui/material/styles';
import { EDTypography, edWebkitTheme } from "@trailblazer-game/ed-webkit";
import Stack from "@mui/material/Stack";
import { useWallet } from "@solana/wallet-adapter-react";
import { IWithUserDataProps, withUserData } from "../../hooks/UserDataHook/UserDataContext";
import useMediaQuery from "@mui/material/useMediaQuery";

//
interface IWalletInfoProps extends IWithUserDataProps {
    className?: string;
    light?: boolean;
    fullPublicKey?: boolean;
}


//
function WalletInfo(props: IWalletInfoProps) {
    const { wallet } = useWallet();
    const isSmall = useMediaQuery(edWebkitTheme.breakpoints.down('sm'));

    if (!wallet) {
        return null;
    }

    let publicKey = wallet.adapter.publicKey?.toBase58();

    if (!props.fullPublicKey && isSmall && publicKey) {
        publicKey = `${publicKey.slice(0, 7)}...${publicKey.slice(publicKey.length - 7, publicKey.length)}`;
    }

    return <Stack spacing={1} direction={"row"}>
        <EDTypography variant={"caption"} sx={{ color: edWebkitTheme.palette.grey[props.light ? '100' : '800'] }}>
            <b>{wallet.adapter.name}</b>
        </EDTypography>
        <EDTypography variant={"caption"} sx={{
            color: edWebkitTheme.palette.grey[props.light ? '50' : '900'],
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: '70vw'
        }}>
            <b>{publicKey}</b>
        </EDTypography>
    </Stack>
}

const WalletInfoStyled = styled(WalletInfo)(() => ({}));

// Export
export default withUserData(WalletInfoStyled, false);