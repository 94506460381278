import axios from 'axios';
import { APIResult } from '../APIResult';

export async function getUserInventory(tbUid: string): Promise<APIResult> {
    let url = `${process.env.NEXT_PUBLIC_API_URL}/inventory/user/${tbUid}`;

    let data = {
        filters: {}
    };

    try {
        let res = await axios.post(url, data);
        let resData = res.data;
        if (resData.success) {
            return { status: 0, data: resData.inventory }
        } else {
            return { status: -1 }
        }

    } catch (error: any) {

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log('Error', error.response.data);
            console.log('Error', error.response.status);
            console.log('Error', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log('Error', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log('Error', error.config);

        return { status: -1, error: { code: -5 } }
    };
}