import axios from 'axios';

export async function deleteLogin(provider: string, providerId: string, token: string): Promise<any> {
    try {

        let res = await axios.delete(`${process.env.NEXT_PUBLIC_GATE}/login`,
            { headers: { Authorization: token }, data: { provider, providerId } }
        );

        console.log("delete login", res)
        return res.data;
    } catch (error: any) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log('Error', error.response.data);
            console.log('Error', error.response.status);
            console.log('Error', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log('Error', error.config);
        return null;
    };

}