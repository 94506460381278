import React, { memo } from "react";
import Stack from "@mui/material/Stack";
import { SessionContext, SessionContextInterface } from "../../hooks/SessionHook/SessionContext";
import Link from "next/link";
import { EPage } from "../../helpers/constants";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { EDIcon, EDIconSize, grey, info, spacing } from "@trailblazer-game/ed-webkit";
import { useWallet } from "@solana/wallet-adapter-react";
import { styled } from "@mui/material/styles";
import { PRODUCTION } from "../../helpers/environment";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { theme } from "../Theme/Theme";

interface IAppBarUserMenuProps {
  className?: string;
  handleUserMenuChange: (e: React.MouseEvent<HTMLDivElement>, page: EPage) => void;
}

const AppBarUserMenu = memo(({ className, handleUserMenuChange }: IAppBarUserMenuProps) => {
  const { disconnect } = useWallet();
  const { clearSession, identity } = React.useContext(SessionContext) as SessionContextInterface;
  const router = useRouter();
  const { t } = useTranslation();

  const logout = async () => {
    clearSession();
    await disconnect();

    router.push("/login");
  }

  return <Stack className={`${className} user-menu card-shadow`} alignItems={"stretch"}
                justifyContent={"flex-end"}>
    <Link href={`/${EPage.PUBLIC_PROFILE}/${identity.nickname}`} passHref>
      <Box className={router.asPath == `/${EPage.PUBLIC_PROFILE}/${identity.nickname}` ? "link active" : "link"}
           onClick={(e) => handleUserMenuChange(e, EPage.PUBLIC_PROFILE)}>
        {t("menu.public_profile")}
      </Box>
    </Link>
    <Link href={`/${EPage.MY_GALLERY}`} passHref>
      <Box className={router.pathname == `/${EPage.MY_GALLERY}` ? "link active" : "link"}
           onClick={(e) => handleUserMenuChange(e, EPage.MY_GALLERY)}>
        {t("menu.gallery")}
      </Box>
    </Link>

    <Link href={`/${EPage.PROFILE}`} passHref>
      <Box className={router.pathname == `/${EPage.PROFILE}` ? "link active" : "link"}
           onClick={(e) => handleUserMenuChange(e, EPage.PROFILE)}>
        {t("menu.profile")}
      </Box>
    </Link>
 
    <Box className={"separator"}/>

    <Box className={"link logout"}
         onClick={logout}>
      {t("menu.logout")}
    </Box>

    {!PRODUCTION && <>
      <Box className={"separator"}/>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
        <EDIcon icon={<DesignServicesIcon sx={{ color: grey("50") }}/>} size={EDIconSize.Small}/>
        <Box className={"link debug"}
             onClick={() => document.body.classList.toggle("debug-rects")}>
          Show boundaries
        </Box>
      </Stack>
    </>}
  </Stack>
});

AppBarUserMenu.displayName = "AppBarUserMenu";

const AppBarUserMenuStyled = styled(AppBarUserMenu)({
  position: "absolute",
  bottom: 0,
  right: spacing(6),
  backgroundColor: grey(900),
  transform: `translate(0, calc(100% + ${spacing(1)}))`,
  transformOrigin: '0 100%',
  margin: '0',
  padding: theme.spacing(2),
  width: '200px',
  borderRadius: '8px',
  ".link": {
    padding: 0,
    margin: `${theme.spacing(0.5, 0)}`,
    justifyContent: "flex-end",
    "&.debug": {
      ...theme.typography.caption,
      color: info("main")
    }
  },
  '> .separator': {
    height: 'auto',
    width: '100%',
    border: `1px solid ${grey(800)}`,
    opacity: '0.5',
    margin: theme.spacing(0.5, 0)
  }
});

export default AppBarUserMenuStyled;