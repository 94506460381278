import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { EDButton, EDButtonType, EDTypography, edWebkitTheme } from "@trailblazer-game/ed-webkit";
import Stack from "@mui/material/Stack";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { ILogin } from "../../helpers/interfaces/userData";
import { useWallet } from "@solana/wallet-adapter-react";
import { SessionContext, SessionContextInterface } from "../../hooks/SessionHook/SessionContext";
import { Connection, PublicKey } from "@solana/web3.js";
import { buildAuthTx } from "../../commands/memosign/MemoSign";
import { bs58 } from "@project-serum/anchor/dist/cjs/utils/bytes";
import { linkWallet } from "../../commands/user/linkWallet";
import {
    IWithUserDataProps,
    UserDataContext,
    IUserDataContextInterface,
    withUserData
} from "../../hooks/UserDataHook/UserDataContext";
import { SnackUi } from "../Pages/Profile/Components/SnackUi";
import { deleteLogin } from "../../commands/user/deleteLogin";
import useMediaQuery from "@mui/material/useMediaQuery";
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';

//
interface IWalletAdapterProps extends IWithUserDataProps {
    className?: string;
}

interface ILoginRow {
    login?: ILogin
    status: "free" | "freeable" | "disabled"
}

//
function WalletAdapter(props: IWalletAdapterProps) {
    const { publicKey, signTransaction, connected, connect } = useWallet();
    const { setVisible: setWalletModalVisible, visible: isWalletModalVisible } = useWalletModal();
    const { session } = React.useContext(SessionContext) as SessionContextInterface;
    const { reloadProfileData } = React.useContext(UserDataContext) as IUserDataContextInterface;
    const [ snackMsg, setSnackMsg ] = useState<string | null>(null);
    const [ wasConnecting, setWasConnecting ] = useState<boolean>(false);
    const [ unlinkInProgress, setUnlinkInProgress ] = useState<string>("")
    const isSmall = useMediaQuery(edWebkitTheme.breakpoints.down('sm'));

    useEffect(() => {
        if (isWalletModalVisible) {
            return;
        }

        if (!connected) {
            return;
        }

        if (!publicKey) {
            return;
        }

        signWallet();
    }, [ publicKey, isWalletModalVisible, connected ])

    const getConnection = (): Connection => {
        const customRpcUrl = process.env.NEXT_PUBLIC_SOLANA;
        const connection = new Connection(
            //@ts-ignore
            customRpcUrl
        );
        return connection;
    }

    const signTx = async (publicKey: PublicKey | undefined | null) => {
        return new Promise<boolean>((resolve, reject) => {
            if (signTransaction && publicKey) {
                getConnection()
                    .getLatestBlockhash()
                    .then(blockhash => {
                        const tx = buildAuthTx("Welcome to Eternal Dragons")
                        tx.feePayer = publicKey // not sure if needed but set this properly
                        tx.recentBlockhash = blockhash.blockhash;
                        // Encode and send tx to signer, decode and sign
                        signTransaction(tx)
                            .then(signedTx => {

                                let buffer = signedTx.serialize();
                                let signature: string = bs58.encode(buffer);


                                connectAccount("wallet", signature, publicKey?.toBase58())
                                    .then(res => {
                                        console.log("connected");
                                        resolve(true);
                                    })
                                    .catch(ex => {
                                        setSnackMsg("Something went wrong while linking the wallet.");
                                        reject();
                                        console.warn(ex);
                                    });
                            })
                            .catch(ex => {
                                connect();
                                setSnackMsg("Something went wrong while linking the wallet.");
                                reject();
                                console.warn(ex);
                            });
                    });
            }
        });
    }

    const signWallet = () => {
        if (sessionStorage.getItem("auto_link") !== "true" && !wasConnecting) {
            return;
        }

        if(props.userData.hasLinkedWalletWithPublicKey(publicKey?.toBase58())) {
            setSnackMsg("This wallet is already linked with your account.");
            return;
        }

        signTx(publicKey)
            .then(() => {
                props.reloadUserData();
            })
            .catch(e => {
                setSnackMsg("Something went wrong. Cannot link the wallet.");
            })
            .finally(() => {
                sessionStorage.setItem("auto_link", "false");
                setWasConnecting(false);
            });
    }

    const getWellReason = (reason: string) => {
        if (reason == "account_already_linked") {
            if (props.userData.hasLinkedWalletWithPublicKey(publicKey?.toBase58())) {
                return "This wallet is already linked in your account.";
            }
            return "This wallet is connected to another account.";
        }

        return reason;
    }

    const connectAccount = async (provider: string, signature?: string, wallet?: string) => {
        let res: any = null;
        let successMsg = provider + " account connected!";
        let errMsg = "error connecting " + provider + " account!";

        if (signature && wallet) {
            res = await linkWallet(wallet, signature, session as string);
        }

        errMsg = `Error - Can't add wallet`;

        if (res && res.data) {
            if (res.data.success === true) {
                await reloadProfileData();
            } else {
                setSnackMsg(getWellReason(res.data.reason));
            }
        } else {
            setSnackMsg(errMsg);
        }
    }

    const disconnectWallet = async (provider: string, providerId?: string, successMsg?: string) => {
        if (!providerId) {
            return;
        }

        const logins: ILogin[] = props.userData.profileData.logins || [];

        if (!logins || !logins.length) {
            setUnlinkInProgress("");
            return;
        }

        let res: any = null;

        res = await deleteLogin(provider, providerId, session as string);

        if (res) {
            if (res.success === false) {
                if (res.reason === 'cant_unlink_last_account') {
                    setSnackMsg(`You can't unlink your only login access`);
                }
            } else {
                await props.reloadUserData();
                setSnackMsg(successMsg ? successMsg : provider + " account disconnected!");
            }
        }

        setUnlinkInProgress("");
    }


    async function removeWalletHandler(wallet: string) {
        let token = session;

        if (token && wallet) {
            setUnlinkInProgress(wallet);
            await disconnectWallet("wallet", wallet, "Wallet removed!");
        }
    }

    const addWalletHandler = async (rowIndex: number) => {
        if (!session) {
            return;
        }

        if (!connected) {
            sessionStorage.setItem("auto_link", "true");
        }

        setWasConnecting(true);
        setWalletModalVisible(true);
    }

    const closeSnack = () => {
        setSnackMsg(null);
    }

    const getWalletLogins = () => {
        const rows: ILoginRow[] = [];
        const actualLogins = props.userData.walletLogins();

        actualLogins.map(login => rows.push({ login, status: "freeable" }));

        while (actualLogins.length < 2 && rows.length < actualLogins.length + 1) {
            rows.push({ status: props.userData.hasLinkedWalletWithPublicKey(publicKey?.toBase58()) ? "disabled" : "free" });
        }

        return rows;
    }

    const renderLogins = () => {
        const rows = getWalletLogins();
        const result: React.ReactNode[] = [];

        rows.map((row: ILoginRow, index: number) => {
            switch (row.status) {
                case "disabled":
                case "free":
                    result.push(
                        <Stack className={"login-row"} direction={"row"} alignItems={"center"}
                               justifyContent={"center"}
                               spacing={2} key={index}>
                            <EDButton className={"provider-button"}
                                      type={EDButtonType.Bones}
                                      label={connected ? "Link Wallet" : "Connect"}
                                      onClick={() => addWalletHandler(index)}
                                      aria={{ label: row.status == "disabled" ? "This wallet is already linked" : "Add active wallet to your profile." }}
                                      icon={<LinkIcon />}
                            />
                        </Stack>
                    );
                    break;
                case "freeable":
                    result.push(
                        <Stack className={"login-row"} direction={"row"} alignItems={"center"}
                               justifyContent={"center"} spacing={2}
                               key={index}>
                            <EDTypography className={"provider-id-label"}
                                          variant={"body1"}>{row.login!.providerId}</EDTypography>
                            <EDButton className={"provider-button"}
                                      type={EDButtonType.Error}
                                      label={isSmall ? "" : "Unlink"}
                                      onClick={() => removeWalletHandler(row.login!.providerId as string)}
                                      inProg={unlinkInProgress == row.login!.providerId}
                                      icon={<LinkOffIcon />}
                                      aria={{ label: "Remove this wallet from your profile." }}/>
                        </Stack>
                    );
                    break;
            }
        });

        return result;
    }

    return <>
        <Stack className={props.className} justifyContent={"flex-start"} alignItems={'center'} spacing={4}>
            <Stack spacing={1} alignItems={isSmall ? 'center' : 'flex-start'} sx={{ width: '100%' }}>
                <EDTypography variant={"body2"}><b>Linked wallets
                    ({props.userData.walletLogins().length}/2):</b></EDTypography>
                {renderLogins()}
            </Stack>
            <SnackUi snackMsg={snackMsg} title={"Info"} closeSnack={closeSnack}/>
        </Stack>
    </>;
}

const WalletAdapterStyled = styled(WalletAdapter)(() => ({
    width: "100%",
    maxWidth: "500px",
    position: "relative",
    "td": {
        border: "none"
    },
    ".login-row": {
        width: "100%",
        backgroundColor: edWebkitTheme.palette.grey['800'],
        padding: edWebkitTheme.spacing(1),
        borderRadius: '10px',
        minWidth: '500px'
    },
    ".provider-id-label": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        flexGrow: 1
    },
    [edWebkitTheme.breakpoints.down('sm')]: {
        maxWidth: "100%",
        ".provider-id-label": {
            maxWidth: "40vw",
        },
        ".login-row": {
            minWidth: '60vw'
        }
    }
}));

// Export
export default withUserData(WalletAdapterStyled, false);