import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import Link from "next/link";
import { active, EDTypography, edWebkitTheme, error as edError, grey, spacing } from "@trailblazer-game/ed-webkit";
import Stack from "@mui/material/Stack";
import { useRouter } from "next/router";
import Accordion from "@mui/material/Accordion";
import Slide from "@mui/material/Slide";
import { SlideProps } from "@mui/material/Slide/Slide";
import { IMenuOption } from "../../AppBar/AppBar";
import { theme } from "../../Theme/Theme";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from "@mui/material/Box";
//
const MyMainMenu = styled(Menu)({
  ".MuiMenu-paper": {
    position: 'fixed',
    top: `0 !important`, left: `0 !important`, right: `0 !important`, bottom: `0 !important`,
    width: '100vw',
    height: `100vh`,
    maxWidth: '100vw',
    maxHeight: `100vh`,
    backgroundColor: 'rgba(0,0,0,0.75)',
    paddingTop: edWebkitTheme.spacing(12),
    borderRadius: 0,
    "& li": {
      transition: "all .1s linear",
      fontWeight: 700,
      fontSize: "14px",
      padding: "8px 25px 8px 25px",
      "&:hover": {
        background: "#383d40"
      }
    }
  }
});


interface IMainMenuProps {
  className?: string;
  anchorEl?: Element;
  open: boolean;
  handleClose: (e: any, page: string) => void;
  menuOpts: IMenuOption[];
}

function MainMenu({ className, anchorEl, open, handleClose, menuOpts }: IMainMenuProps) {
  const router = useRouter();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const renderLink = (el: Partial<IMenuOption>, index: number, className?: string) =>
    <Box key={`${el.link}-${index}`} sx={{ display: "contents" }}>
      <Link href={el.link || ""} passHref key={`${el.link}-${index}`}>
        <a>
          <EDTypography variant={"body1"} onClick={(e) => handleClose(e, el.link || "")} align={"center"}
            className={router.pathname == el.link ? `link active ${el.className || ''} ${className}` : `link ${el.className || ''} ${className}`}>
            {el.label}
          </EDTypography>
        </a>
      </Link>
      {
        el.content ?? null
      }
    </Box>;

  const renderAccordion = (el: IMenuOption) =>
    <Accordion
      onChange={handleChange(el.id)}
      key={`${el.id}-${el.label}`}
      expanded={expanded === el.id}
      sx={{
        backgroundColor: "transparent",
        color: grey("200"),
        width: "100%",
        "svg": {
          color: grey("50"),
          width: "24px",
          height: "24px"
        },
        ".MuiAccordionSummary-content": {
          flexGrow: 0,
          margin: 0,
          "&.Mui-expanded": {
            margin: 0
          }
        },
        ".MuiAccordionDetails-root": {
          padding: 0
        }
      }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1a-content"
      >
        <EDTypography className={"link"}>{el.label}</EDTypography>
      </AccordionSummary>
      <AccordionDetails>
        {(el.items || []).map((item, i) => renderLink(item, i, "accordion"))}
      </AccordionDetails>
    </Accordion>

  return (
    <MyMainMenu
      className={`${className} ${expanded ? "expanded" : ""} : ''`}
      anchorEl={anchorEl}
      open={open}
      onClose={(e) => handleClose(e, "")}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'down' } as SlideProps}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 0,
      }}>

      <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
        {
          menuOpts.map((el: IMenuOption, i: number) => {
            return <Stack direction={"row"} alignItems={"center"} key={`${el.id}-${i}`}>
              {
                el.link ? renderLink(el, i) : el.items ? renderAccordion(el) : null
              }
            </Stack>
          })
        }
      </Stack>
    </MyMainMenu>
  );
}

// Default style!
const MyMenuStyled = styled(MainMenu)({
  paddingTop: '6rem',
  "&.expanded":{
    ".link": {
      "&:not(.accordion)": {
        opacity: 0.5,
        "svg": {
          opacity: 0.5
        }
      }
    },
    ".MuiAccordionSummary-expandIconWrapper": {
      opacity: 0.5
    }
  },
  'span, p': {
    fontFamily: 'Figtree',
  },
  ".live-dot": {
    backgroundColor: active("error"),
    color: grey("50"),
    borderRadius: "4px",
    marginLeft: spacing(1),
    padding: theme.spacing(0.5, 1),
    "svg": {
      marginBottom: "4px"
    }
  },
  ".new-tag": {
    color: edError("main")
  },
  '.link': {
    textTransform: 'uppercase',
    color: edWebkitTheme.palette.grey['50'],
    fontWeight: '500',
    cursor: 'pointer',
    padding: '12px',
    fontSize: '1rem',
    '&:hover': {
      textShadow: `1px 0 0.5rem #ffe797`
    },
    '&.active': {
      color: '#ffea9a'
    },
    "&.accordion": {
      ...theme.typography.caption,
      fontWeight: 800,
      padding: theme.spacing(1, 0),
      color: grey("200")
    },
    '&.game': {
      border: `3px solid ${edWebkitTheme.activeColors.primary}`,
      height: '32px',
      padding: edWebkitTheme.spacing(0, 2),
      backgroundColor: edWebkitTheme.activeColors.primary,
      color: edWebkitTheme.palette.grey['900'],
      fontWeight: '700',
      display: 'flex',
      placeContent: 'center',
      placeItems: 'center',
      borderTopRightRadius: '10px',
      borderBottomLeftRadius: '10px',
      transition: 'all .25s ease-in-out',
      '&:hover': {
        textShadow: `none`,
        backgroundColor: edWebkitTheme.palette.grey['900'],
        color: edWebkitTheme.activeColors.primary
      },
      '&.active': {
        backgroundColor: edWebkitTheme.palette.primary.light,
        color: edWebkitTheme.palette.grey['900']
      }
    }
  }
});

// Export
export default MyMenuStyled;
