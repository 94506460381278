export const getMessages = `
  query GetMessages($count: Int, $nextToken: String) {
    getMessages(count: $count, nextToken: $nextToken) {
      messages {
        tbUid
        msgUid
        msg
        viewed
        createdAt
        category
      }
      nextToken
    }
  }
`;
