import { useEffect, useState } from "react";

/*
* A store is an observable object with internal state.
* This is a solution to use instead of contexts, to reduce the amount of contexts we need
*/
const createEmitter = () => {
    const subscriptions = new Map();
    return {
        emit: (v: any) => subscriptions.forEach(fn => fn(v)),
        subscribe: (fn: any) => {
            const key = Symbol();
            subscriptions.set(key, fn);
            return () => subscriptions.delete(key);
        },
    }
};

export function createStore<T>(
    init: (get: () => T, set: (op: Function) => void, setAsync: (opt: Function) => void) => T
) {
    // create an emitter
    const emitter = createEmitter();

    let store: any = null;
    const get = () => store;

    const set = (op: any) => {
        store = op(store); // run the function that will mutate the store
        emitter.emit(store); // emit change to subscribers
    };

    const setAsync = async (op: any) => {
        store.loading = true;
        emitter.emit(store); // emit change to subscribers

        store = await op(store); // run the function that will mutate the store

        store.loading = false;
        emitter.emit(store); // emit change to subscribers
    };

    store = init(get, set, setAsync);
    const useStore = () => {
        // intitialize component with latest store
        const [ localStore, setLocalStore ] = useState(get());

        // update our local store when the global
        // store updates.
        //
        // emitter.subscribe returns a cleanup
        // function, so react will clean this
        // up on unmount.
        useEffect(() => {
            return emitter.subscribe(setLocalStore) as any;
        }, []);

        return localStore;
    };

    return useStore;
};