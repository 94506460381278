import { warning, error as edError, info } from "@trailblazer-game/ed-webkit";
import { PRODUCTION_BLUE } from "../environment";

export function log(prefix: string, message: string, data: any = "") {
    if (PRODUCTION_BLUE) {
        return;
    }

    console.log(`%c [${prefix}]: ${message} %o`, `background: #222; color: ${info("main")}`, data);
}

export function warn(prefix: string, message: string, data: any = "") {
    console.log(`%c [${prefix}]: ${message} %o`, `background: #222; color: ${warning("main")}`, data);
}

export function error(prefix: string, message: string, data: any = "") {
    console.log(`%c [${prefix}]: ${message} %o`, `background: #222; color: ${edError("main")}`, data);
}