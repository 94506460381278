import React, { useEffect, useReducer } from "react";
import { IClientData, IProfileData, UserData } from "../../helpers/interfaces/userData";
import { EDLoader, edWebkitTheme } from "@trailblazer-game/ed-webkit";
import { SessionContext, SessionContextInterface } from "../SessionHook/SessionContext";
import axios from "axios";
import { getLogins } from "../../commands/user/getLogins";
import { useRouter } from "next/router";
import { getUserInventory } from "../../commands/inventory/getUserInventory";
import { warn } from "../../helpers/utils/log";
import { EPage } from "../../helpers/constants";
import { clientAPI as guildClientApi } from "@trailblazer-game/guild-sdk";
import { IInventoryDragon } from "../../helpers/interfaces/dragon";
import { sortByNumberInName } from "../../components/Pages/PublicProfile/Helpers/helpers";

export const UserDataContext = React.createContext<IUserDataContextInterface | null>(null);

const getUserProfileData = async (session: string): Promise<IProfileData> => {
    try {
        const url = `${process.env.NEXT_PUBLIC_PROFILE_SERVICE_URL}/client/getProfile`;
        const res = await axios.post(url, {}, { headers: { 'Authorization': session } });

        if (res && res.data && res.data.success) {
            return res.data.profile;
        }
        return { client: null, readonly: null };
    } catch (ex) {
        console.error(ex);
        return { client: null, readonly: null };
    }
}

const getUserLogins = async (session: string, tbUid: string) => {
    const data = await getLogins(session, tbUid);
    return data;
}

const updateUserProfileData = async (session: string, clientData: Partial<IClientData>): Promise<boolean> => {
    try {
        const url = `${process.env.NEXT_PUBLIC_PROFILE_SERVICE_URL}/client/updateProfile`;
        const res = await axios.post(url, clientData, { headers: { 'Authorization': session } });
        if (res && res.data && res.data.success) {
            return res.data.success;
        }
        return false;
    } catch (ex) {
        console.warn(ex);
        return false;
    }
}

export interface IUserDataContextInterface {
    children?: React.ReactNode;
    userData: UserData;
    reloadUserData: Function;
    reloadProfileData: Function;
    updateProfileData: (clientData: Partial<IClientData>) => Promise<boolean>;
    loading: boolean;
    dataLoaded: boolean;
    testUserUpdate: Function;
}

interface IUserDataProviderState {
    userData: UserData;
    loading: number;
    dataLoaded: boolean;
    logMessage?: { message: string, color: string, icon?: React.ReactElement };
}

const initialState: IUserDataProviderState = {
    userData: new UserData(),
    loading: 0,
    dataLoaded: false
}

enum EUserDataProviderActions {
    LoadUserData,
    LoadUserDataSuccess,
    LoadUserDataFail,
    LoadProfileData,
    LoadProfileDataSuccess,
    LoadProfileDataFail,
    UpdateProfileData,
    UpdateProfileDataSuccess,
    UpdateProfileDataFail,
}

interface IUserDataProviderAction {
    type: EUserDataProviderActions;
    userData?: UserData;
    profileData?: IProfileData;
    logMessage?: { message: string, color: string, icon?: React.ReactElement };
}

const userDataProviderCreateReducer = (state: IUserDataProviderState, action: IUserDataProviderAction): IUserDataProviderState => {
    switch (action.type) {
        case EUserDataProviderActions.LoadUserData:
            return {
                ...state,
                loading: state.loading + 1
            };

        case EUserDataProviderActions.LoadUserDataSuccess:
            return {
                ...state,
                userData: action.userData || new UserData(),
                loading: state.loading - 1
            };

        case EUserDataProviderActions.LoadUserDataFail:
            return {
                ...state,
                loading: state.loading - 1,
                logMessage: action.logMessage
            };

        case EUserDataProviderActions.LoadProfileData:
            return {
                ...state,
                loading: state.loading + 1
            };

        case EUserDataProviderActions.LoadProfileDataSuccess:
            return {
                ...state,
                dataLoaded: true,
                loading: state.loading - 1,
                userData: new UserData({
                    ...state.userData,
                    profileData: { ...state.userData.profileData, ...action.profileData }
                })
            };

        case EUserDataProviderActions.LoadProfileDataFail:
            return {
                ...state,
                loading: state.loading - 1,
                logMessage: action.logMessage
            };

        case EUserDataProviderActions.UpdateProfileData:
            return {
                ...state,
                loading: state.loading + 1
            };

        case EUserDataProviderActions.UpdateProfileDataSuccess:
            return {
                ...state,
                loading: state.loading - 1,
                userData: new UserData({
                    ...state.userData,
                    profileData: { ...state.userData.profileData, ...action.profileData }
                })
            };

        case EUserDataProviderActions.UpdateProfileDataFail:
            return {
                ...state,
                loading: state.loading - 1,
                logMessage: action.logMessage
            };

        default:
            return state;
    }
}


export const UserDataProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [ state, dispatch ] = useReducer(userDataProviderCreateReducer, initialState);
    const { session, getTBUid } = React.useContext(SessionContext) as SessionContextInterface;
    const router = useRouter();

    async function getUserData(session: string, tbUid: string) {
        dispatch({ type: EUserDataProviderActions.LoadUserData });

        const userInventory = await getUserInventory(tbUid);

        if(userInventory.status < 0 || !userInventory.data) {
            dispatch({
                type: EUserDataProviderActions.LoadProfileDataFail,
                logMessage: {
                    message: "Something went wrong while getting inventory data.",
                    color: edWebkitTheme.palette.error.main
                }
            });
            return;
        }
        // Sort dragons
        const genesisDragons = sortByNumberInName(userInventory.data.Genesis);
        const descendantDragons = sortByNumberInName(userInventory.data.Descendant);
        const descendantEggs = userInventory.data.descendantEggs;
        const ticketsPlayerCards = userInventory.data.ticketsPlayerCards;
        const sagaCards = userInventory.data.sagaCards;
        const Minion = sortByNumberInName(userInventory.data.Minion);
        const Trophy = sortByNumberInName(userInventory.data.Trophy);
        const Medal = sortByNumberInName(userInventory.data.Medal);
        const Other = sortByNumberInName(userInventory.data.Other);
        
        const newUserData = new UserData({
            ...state.userData,
            Genesis: genesisDragons,
            Descendant: descendantDragons,
            Minion,
            descendantEggs,
            ticketsPlayerCards,
            sagaCards,
            Trophy,
            Medal,
            Other
        });

        if (session && tbUid) {
            const profileData = await getProfileData(session, tbUid);

            newUserData.profileData = {
                ...newUserData.profileData,
                ...profileData
            };
        }

        dispatch({ type: EUserDataProviderActions.LoadUserDataSuccess, userData: newUserData });

        console.log('Loaded user data: %o', newUserData);

        return newUserData;
    }
    

    async function getProfileData(session: string, tbUid: string) {
        dispatch({ type: EUserDataProviderActions.LoadProfileData });

        let profileDataResponse = await getUserProfileData(session);
        let logins = await getUserLogins(session, tbUid);

        const newProfileData = { ...profileDataResponse, logins: logins || [] };
        console.log('Loaded profile data: %o', newProfileData);

        dispatch({
            type: EUserDataProviderActions.LoadProfileDataSuccess,
            profileData: newProfileData
        });

        return newProfileData;
    }

    const reloadUserData = async () => {
        if (!session) {
            return;
        }

        const newUserData = await getUserData(session as string, getTBUid() as string);
        return newUserData;
    }

    const reloadProfileData = async () => {
        if (!session) {
            return;
        }

        const newProfileData = await getProfileData(session as string, getTBUid() as string);
        return new UserData({...state.userData, profileData: newProfileData});
    }

    const updateProfileData = async (clientData: IClientData): Promise<boolean> => {
        if (!session) {
            return false;
        }

        dispatch({ type: EUserDataProviderActions.UpdateProfileData });

        const newProfileData = {
            ...state.userData.profileData,
            client: { ...state.userData.profileData.client, ...clientData }
        };

        const result = await updateUserProfileData(session as string, clientData);

        if (result) {
            dispatch({ type: EUserDataProviderActions.UpdateProfileDataSuccess, profileData: newProfileData });
            return true;
        } else {
            dispatch({
                type: EUserDataProviderActions.UpdateProfileDataFail,
                logMessage: {
                    message: "Something went wrong while updating profile data.",
                    color: edWebkitTheme.palette.error.main
                }
            });

            return false;
        }
    }

    const testUserUpdate = (data: UserData) => {
        warn("UserDataContext", `Testing user data update with data:`, data);

        dispatch({
            type: EUserDataProviderActions.LoadUserDataSuccess,
            userData: data
        });
    }

    useEffect(() => {
        if (session) {
            console.log("Start fetching user data");
            reloadUserData();
        }
    }, [ session ]);

    useEffect(() => {
        if (state.userData.needWelcome(router.pathname)) {
            router.push(`/${EPage.WELCOME}`);
        }
    }, [ state.userData.profileData.client ]);

    return (
        <UserDataContext.Provider value={{
            userData: state.userData,
            loading: state.loading > 0,
            dataLoaded: state.dataLoaded,
            reloadUserData,
            updateProfileData,
            reloadProfileData,
            testUserUpdate
        }}>
            {children}
        </UserDataContext.Provider>
    );
}

export interface IWithUserDataProps extends IUserDataContextInterface {
}

// https://react-typescript-cheatsheet.netlify.app/docs/hoc/full_example/

export function withUserData<T extends IWithUserDataProps = IWithUserDataProps>(Component: React.ComponentType<T>, bShowLoader = true) {
    const WithUserDataComponent = (props: Omit<T, keyof IWithUserDataProps>) => {
        const router = useRouter();
        return <UserDataContext.Consumer>
            {(state) => {
                if (!state?.dataLoaded) {
                    return <EDLoader in={state?.loading || false} loadingText={'Loading User Data...'}/>;
                }


                if (state?.userData.needToS(router.pathname)) {
                    router.push(`/${EPage.TOS}`);
                    return <EDLoader in={state?.loading || false} loadingText={'Welcome!'}/>;
                }

                if (state?.userData.needWelcome(router.pathname)) {
                    router.push(`/${EPage.WELCOME}`);
                    return <EDLoader in={state?.loading || false} loadingText={'Welcome!'}/>;
                }

                return <>
                    <Component {...({ ...state, ...(props as T) })}/>
                    {bShowLoader && <EDLoader in={state?.loading || false} loadingText={'Loading User Data...'}/>}
                </>
            }}
        </UserDataContext.Consumer>
    };

    return WithUserDataComponent;
}