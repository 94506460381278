// Navigation
export enum EPage {
  TOS="tos",
  HOME = "home",
  ABOUT = "about",
  COMMUNITY = "community",
  NEWS = "news",
  BREEDING = "breeding",
  WHITEPAPER = "whitepaper",
  PRIVACY_POLICY = "privacy-policy",
  TERMS_AND_CONDITIONS = "terms-and-conditions-of-service",
  RANKING = "ranking",
  PROFILE = "profile",
  QUESTS = "quests",
  MY_GALLERY = "mygallery",
  GALLERY = "gallery",
  MINT = "mint",
  NFT_DETAILS = "nft_details",
  TEAM = "team",
  GAME = "game",
  LEADERBOARD = "leaderboard",
  LOGIN = "login",
  EVENT_PAGE = "event_page",
  NOTFOUND = 'notfound',
  REGISTER = 'register',
  WELCOME = "welcome",
  NOTIFICATIONS = "user/notifications",
  LEAGUE = "league",
  TOURNAMENT = "tournament",
  QUESTMODE = "questMode",
  WATCHNOW = "watchnow",
  DOWNLOAD = "download",
  PUBLIC_PROFILE = "u",
  LEAGUE_SOLO = "league/solo",
  LEAGUE_GUILD = "league/guild",
  SAGA = "saga"
}

export const SOCIAL_URLS: any = {
  medium: "https://eternaldragons.medium.com/",
  discord: "https://discord.gg/suXBKpn5BW",
  twitter: "https://twitter.com/EternalDragons_"
}