import React, { ForwardedRef, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { EDTypography, edWebkitTheme, error, grey, primary, scrollBar, spacing } from "@trailblazer-game/ed-webkit";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material"
import { SessionContext, SessionContextInterface } from "../../hooks/SessionHook/SessionContext";
import { INotification } from "../../helpers/interfaces/notification";
import { EPage } from "../../helpers/constants";
import DeleteIcon from '@mui/icons-material/Delete';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useRouter } from "next/router";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import RefreshIcon from '@mui/icons-material/Refresh';
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../Theme/Theme";
import NotificationListItem from "./NotificationsListItem";
import { useNotificationsStore } from "../../stores/notifications/notificationsStore";
import { STAGING } from "../../helpers/environment";

interface IMenuNotificationsListProps {
    className?: string;
    onNewNotificationChange?: (newNotifications: boolean) => any;
    onViewAll?: () => any;
}

function MenuNotificationsList(props: IMenuNotificationsListProps) {
    const { session } = useContext(SessionContext) as SessionContextInterface;
    const router = useRouter();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        loadNotifications,
        dataLoaded,
        hasNewNotifications,
        setNotificationViewed,
        setAllNotificationsViewed,
        deleteNotifications,
        loadMore,
        visibleNotifications,
        notifications
    } = useNotificationsStore();

    const [ scrollContainerRef, infiniteScrollLoading, setHasMore ] = useInfiniteScroll(() => {
        return new Promise((resolve) => {
            // make a request to load more items
            setTimeout(() => {
                loadMore();
                resolve(true);
                // @ts-ignore
                setHasMore(visibleNotifications.length < notifications.length);
            }, 1000);
        });
    });

    useEffect(() => {
        if (!session) {
            return;
        }

        if (!dataLoaded) {
            loadNotifications(session as string);
        }
    }, [ session ]);

    useEffect(() => {
        if (props.onNewNotificationChange) {
            props.onNewNotificationChange(hasNewNotifications);
        }
    }, [ hasNewNotifications ])

    const onNotificationClick = (notification: INotification, event: React.MouseEvent<HTMLDivElement>) => {
        if (!notification.viewed) {
            onNotificationViewed(notification, event);
        }
    }

    const onNotificationViewed = async (notification: INotification | undefined, e: any = null) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!notification || !notification.msgUid) {
            return;
        }

        await setNotificationViewed(notification, session as string);
    }

    const markAllAsRead = async (e: any) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        await setAllNotificationsViewed(session as string);
    }

    const deleteAll = async (e: any) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        await deleteNotifications(session as string);
    }

    const viewAll = () => {
        if (props.onViewAll) {
            props.onViewAll();
        }

        router.push(`/${EPage.NOTIFICATIONS}`);
    }


    const renderNotifications = () => visibleNotifications.map((notification: INotification, index: number) => (
        <NotificationListItem className={"notification"}
                              notification={notification}
                              key={index}
                              ref={(index == visibleNotifications.length - 2) ? scrollContainerRef as ForwardedRef<HTMLDivElement> : undefined}/>
    ));

    const renderNoNotifications = () => (
        <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "100%", color: grey(400) }} spacing={2}>
            <SentimentVeryDissatisfiedIcon/>
            <EDTypography variant={"body1"}>
                No notifications, <i>yet.</i>
            </EDTypography>
        </Stack>
    );

    return <Stack className={props.className} alignItems={"stretch"} justifyContent={"center"} spacing={1}>
        <Stack className={"toolbar"} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <EDTypography variant={isSmall ? "h6" : "h3"} onClick={() => viewAll()} sx={{ cursor: 'pointer' }}>
                Notifications
            </EDTypography>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>
                <EDTypography variant={isSmall ? "caption" : "button"}
                              onClick={(e) => markAllAsRead(e)}
                              sx={{ color: primary("main"), cursor: 'pointer' }}>
                    MARK ALL AS READ
                </EDTypography>
                {STAGING && <DeleteIcon onClick={(e) => deleteAll(e)} sx={{ fill: error("main"), cursor: 'pointer' }}/>}
            </Stack>
        </Stack>
        <Stack className={"notifications-container"} spacing={0.5} alignItems={"center"}>
            {visibleNotifications.length ? renderNotifications() : renderNoNotifications()}
            {infiniteScrollLoading ? <Box>
                <RefreshIcon className={"refresh-icon rotate-center"}/>
            </Box> : null}
        </Stack>
        <Stack className={"footer"} alignItems={"center"} justifyContent={"center"}>
            <EDTypography variant={"button"} onClick={() => viewAll()} sx={{ color: primary("main") }}>
                See all notifications
            </EDTypography>
        </Stack>
    </Stack>
}

const MenuNotificationsListStyled = styled(MenuNotificationsList)({
    width: '100%',
    height: '100%',
    cursor: 'default',
    color: grey(50),
    ".toolbar": {
        padding: edWebkitTheme.spacing(1, 0),
        "svg": {
            width: '32px',
            height: '32px',
        }
    },
    ".notifications-container": {
        overflowY: "auto",
        height: "100%",
        paddingRight: spacing(1),
        ...scrollBar(),
        ".notification": {
            backgroundColor: grey(900),
            color: grey(500),
            "&.not-viewed": {
                color: grey(50)
            },
            "&:hover": {
                backgroundColor: grey(800),
            }
        },
        ".refresh-icon": {
            color: grey(400)
        }
    },
    ".footer": {
        borderTop: `1px solid ${grey(800)}`,
        padding: edWebkitTheme.spacing(2, 0, 1.5, 0),
        cursor: 'pointer'
    }
});

export default MenuNotificationsListStyled;