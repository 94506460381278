import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { theme } from "../Theme/Theme";
import { grey } from "@trailblazer-game/ed-webkit";

function AppBarBackground(props: any) {
    const [ showHeaderBackground, setShowHeaderBackground ] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = (_: Event) => {
        setShowHeaderBackground(window.scrollY > 50);
    };

    return <Box className={props.className} sx={{
        backdropFilter: showHeaderBackground ? 'blur(5px)' : 'none'
    }}></Box>
}

const AppBarBackgroundStyled = styled(AppBarBackground)({
    position: 'absolute',
    top: 0, right: 0, bottom: 0, left: 0
});

export default AppBarBackgroundStyled;