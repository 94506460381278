import React from "react";
import { EDAlert } from "@trailblazer-game/ed-webkit";

//
export function SnackUi(props: any) {
    return (
        <EDAlert open={props.snackMsg !== null}
                 onClose={props.closeSnack}
                 autoHideDuration={5000}
                 title={props.title}
                 severity={"success"}
                 message={props.snackMsg}/>
    )
}