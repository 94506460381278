import { createStore } from "../createStore";
import { EDTwitchAPI, ITwitchStreamData, ITwitchUser } from "../../commands/twitch/EDTwitchAPI";

export interface ITwitchStore {
    streaming: boolean;
    wasStreaming: boolean;
    userId: string;
    streamData: ITwitchStreamData | null;
    userData: ITwitchUser | null;
    loadStreamData: () => void;
    loadUserData: () => void;
    setUserId: (userId: string) => void;
}

export const useTwitchStore = createStore<ITwitchStore>((get, set, setAsync) => ({
    streaming: false,
    userId: "817761249",
    streamData: null,
    userData: null,
    wasStreaming: false,
    loadStreamData: () => setAsync((store: ITwitchStore) => _loadStreamData(store)),
    loadUserData: () => setAsync((store: ITwitchStore) => _loadUserData(store)),
    setUserId: (userId: string) => setAsync((store: ITwitchStore) => _setUserId(store, userId))
})) as () => ITwitchStore;

const _loadStreamData = async (store: ITwitchStore) => {
    const streamData = await EDTwitchAPI.getInstance().getStreamData(store.userId || "");
    const streaming = (streamData?.data || []).length > 0;

    return { ...store, streamData, streaming, wasStreaming: !streaming && store.streaming };
}

const _loadUserData = async (store: ITwitchStore) => {
    const userData = await EDTwitchAPI.getInstance().getUserData(store.userId || "");

    return { ...store, userData };
}

const _setUserId = async (store: ITwitchStore, userId: string) => {
    const userData = await EDTwitchAPI.getInstance().getUserData(userId || "");
    const streamData = await EDTwitchAPI.getInstance().getStreamData(userId || "");

    return { ...store, userId, userData, streamData };
}