import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, } from "firebase/analytics";
import { AnalyticEvent } from '../AnalyticEvent';
import { AnalyticProvider } from '../AnalyticFacade';
import * as Sentry from "@sentry/browser";
import { log, warn } from '../../utils/log';


//DEV ANONYMOUS 
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_ANO_ANALYTIC_API,
  authDomain: process.env.NEXT_PUBLIC_ANO_ANALYTIC_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_ANO_ANALYTIC_PROJECTID,
  storageBucket: process.env.NEXT_PUBLIC_ANO_ANALYTIC_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_ANO_ANALYTIC_SENDERID,
  appId: process.env.NEXT_PUBLIC_ANO_ANALYTIC_APPID,
  measurementId: process.env.NEXT_PUBLIC_ANO_ANALYTIC_MEASUREMENT
};


class FireBaseAnalyticAnonymous implements AnalyticProvider {

  analytics: any;

  app: any;

  user: any;

  constructor() {
    this.app = initializeApp(firebaseConfig, "Anonymous");

    /*  if (this.app.name && typeof window !== 'undefined' && !this.analytics) {
          this.analytics = getAnalytics(this.app);
      }

      this.setUserAddress(getIDFASession());*/
  }

  getFirebaseAnalytics() {

    return getAnalytics(this.app);
  }

  setUserAddress(address: string): void {

    Sentry.setUser({ id: address });

    // if (this.app.name && typeof window !== 'undefined' && !this.analytics) {
    //    this.analytics = getAnalytics(this.app);
    // }

    this.user = address;

    // setUserId(this.analytics, address);

  }

  sendEvent(event: AnalyticEvent) {
    log("FireBaseAnalyticAnonymous", "Send anonymous event", {
      ...event.properties,
      'anUid': this.user
    });

    if (this.app.name && typeof window !== 'undefined' && !this.analytics) {
      this.analytics = getAnalytics(this.app);
    }

    logEvent(this.analytics, event.name,
      {
        ...event.properties,
        'anUid': this.user
      });
  }

  sendRawEvent(name: string, parameterName: string, parameterValue: string) {

    console.log("SEND RAW EVENT", name)
    if (this.app.name && typeof window !== 'undefined' && !this.analytics) {
      this.analytics = getAnalytics(this.app);
    }

    if (parameterName != undefined && parameterValue != undefined)
      logEvent(this.analytics, name, {
        parameterName: parameterValue,
        'anUid': this.user
      });
    else
      logEvent(this.analytics, name, {
        'anUid': this.user
      });
  }
}

export let fireBaseAnalyticAnonymous = new FireBaseAnalyticAnonymous();


