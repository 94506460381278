import React from "react";
import Stack from "@mui/material/Stack";
import { SessionContext, SessionContextInterface } from "../../hooks/SessionHook/SessionContext";
import Link from "next/link";
import { EPage } from "../../helpers/constants";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import {
    EDButton,
    EDButtonType,
    EDPlayerAvatar,
    EDTypography,
    edWebkitTheme,
    error,
    grey,
    primary,
    spacing
} from "@trailblazer-game/ed-webkit";
import { truncateString } from "../../helpers/utils/string";
import { facade } from "../../helpers/analytic/AnalyticFacade";
import { MenuPageEvent } from "../../helpers/analytic/event/MenuPageEvent";
import useComponentVisible from "../../hooks/useComponentVisible";
import { useWallet } from "@solana/wallet-adapter-react";
import AppBar_NotificationCounter from "./AppBar_NotificationCounter";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar_UserMenu from "./AppBar_UserMenu";

interface IAppBarProfileButtonProps {
    className?: string;
    handleMainMenuChange: (e: React.MouseEvent<HTMLDivElement>, link: string) => void;
}

function AppBarProfileButton(props: IAppBarProfileButtonProps) {
    const { disconnect } = useWallet();
    const { hasSession, identity, tbUid, clearSession } = React.useContext(SessionContext) as SessionContextInterface;
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const bHasSession = hasSession() && identity;
    const router = useRouter();
    const { t } = useTranslation();
    const isSmall = useMediaQuery(edWebkitTheme.breakpoints.down('sm'));

    const getProfileName = () => {
        let name = identity.nickname || identity.name || "";

        return truncateString(name, 50);
    }

    const handlePlayClick = (event: React.SyntheticEvent, newValue: string) => {
        facade.sendEvent(new MenuPageEvent(newValue));

        router.push(`/${EPage.GAME}`);
    };

    function handleUserMenuChange(e: React.MouseEvent<HTMLDivElement, MouseEvent>, page: EPage): void {
        setIsComponentVisible(false);
        props.handleMainMenuChange(e, page);
    }

    const logout = async () => {
        clearSession();
        await disconnect();

        router.push("/login");
    }

    const onProfileNameClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsComponentVisible(!isComponentVisible);
    }

    return <Stack className={props.className} direction={"row"} alignItems={"center"} justifyContent={"center"}
                  spacing={2}>
        {isSmall && <EDButton type={router.pathname == "/game" ? EDButtonType.Secondary : EDButtonType.Primary}
                              onClick={(e) => handlePlayClick(e, EPage.GAME)}
                              label={t("menu.playTheGame")}
                              sx={{
                                  maxWidth: '112px',
                                  minWidth: '112px',
                                  'span': { ...edWebkitTheme.typography.h4 }
                              }}/>}

        {bHasSession && identity &&
            <Stack spacing={2} direction={"row"} alignItems={"center"} justifyContent={"center"} ref={ref}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}
                       sx={{ position: 'relative' }} onClick={onProfileNameClick}>
                    {isComponentVisible && <AppBar_UserMenu handleUserMenuChange={handleUserMenuChange}/>}

                    <EDTypography variant={"button"} className={"profile-name"}
                                  title={identity.nickname || identity.name || "Your profile"} >
                        {getProfileName()}
                    </EDTypography>

                    <Box className={"profile-avatar"}>
                        <EDPlayerAvatar avatarUrl={`${process.env.NEXT_PUBLIC_CDN_URL}/avatar/${tbUid}?ts=${new Date().getTime()}`}/>
                    </Box>
                </Stack>
            </Stack>
        }

        {!bHasSession &&
            <Link href={`/${EPage.LOGIN}#email`} passHref>
                <Box className={router.pathname == "/login" ? "link active" : "link"}
                     onClick={(e) => props.handleMainMenuChange(e, "login")}>
                    {t(`menu.login`)}
                </Box>
            </Link>
        }
        {!isSmall && <EDButton type={router.pathname == "/game" ? EDButtonType.Secondary : EDButtonType.Primary}
                               onClick={(e) => handlePlayClick(e, EPage.GAME)}
                               label={t("menu.playTheGame")}
                               sx={{
                                   maxWidth: '112px',
                                   minWidth: '112px',
                                   'span': { ...edWebkitTheme.typography.h4 }
                               }}/>}
        {bHasSession && <AppBar_NotificationCounter/>}
    </Stack>
}

const AppBarProfileButtonStyled = styled(AppBarProfileButton)({
    position: "relative",
    cursor: "pointer",
    paddingRight: spacing(2),
    ".profile-name": {
        fontWeight: "bolder",
        color: grey(50),
        textTransform: "none",
        whiteSpace: "nowrap"
    },
    '.link': {
        textTransform: 'uppercase',
        color: edWebkitTheme.palette.grey['50'],
        fontWeight: '500',
        cursor: 'pointer',
        padding: edWebkitTheme.spacing(2),
        fontSize: '0.75rem',
        fontFamily: 'Figtree',
        letterSpacing: '0.1rem',
        textAlign: "right",
        '&:hover': {
            textShadow: `1px 0 0.5rem #ffe797`
        },
        '&.active': {
            color: '#ffea9a'
        },
        "&.logout": {
            color: error("main")
        }
    },
    ".user-menu": {
        position: "absolute",
        bottom: 0,
        right: 0,
        backgroundColor: grey(900),
        transform: `translate(0, calc(100% + ${spacing(1)}))`,
        transformOrigin: '0 100%',
        margin: '0',
        padding: edWebkitTheme.spacing(2),
        width: '200px',
        borderRadius: '8px',
        ".link": {
            padding: 0
        }
    },
    '.game-link': {
        border: `3px solid ${edWebkitTheme.activeColors.primary}`,
        height: '32px',
        padding: edWebkitTheme.spacing(0, 2),
        backgroundColor: edWebkitTheme.activeColors.primary,
        color: edWebkitTheme.palette.grey['900'],
        fontWeight: '700',
        display: 'flex',
        placeContent: 'center',
        placeItems: 'center',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        transition: 'all .25s ease-in-out',
        '&:hover': {
            textShadow: `none`,
            backgroundColor: edWebkitTheme.palette.grey['900'],
            color: edWebkitTheme.activeColors.primary
        },
        '&.active': {
            backgroundColor: edWebkitTheme.palette.primary.light,
            color: edWebkitTheme.palette.grey['900']
        }
    },
    [edWebkitTheme.breakpoints.down("sm")]: {
        paddingRight: spacing(2),
        ".profile-name": {
            display: "none"
        }
    }
});

export default AppBarProfileButtonStyled;