import { IInventoryDescendantEgg, IInventoryDragon } from "../../../../helpers/interfaces/dragon"
import { IInventoryTicketPlayerCard } from "../../../../helpers/interfaces/tournament"

// Constants!
export const DRAGON_LABEL = 'Genesis Dragon';
export const DESCENDANT_EGG_LABEL = 'Descendant Egg';
export const ENTRY_TICKET_LABEL = 'Entry Ticket';
export const PLAYER_CARD_LABEL = 'Player Card';
export const MINION_LABEL = 'Minion';
export const DESCENDANT_DRAGON_LABEL = "Descendant Dragon";

export const TWITTER_USER_URL = `https://twitter.com`;
export const DISCORD_USER_URL = `https://discordapp.com/users`;

// Interfaces!
export interface IUserPublicUiData {
    tbUid: string,
    nickname: string,
    social: {
        twitter?: string,
        discord?: string,
        discordId?: string
    },
    createdAt: string
}

export interface IUserPublicUiInventoryItem {
    mint: string,
    label: string,
    id: string,
    imageSm: string,
    image: string,
    index: number,
    count: number,
    rarityLabel: string
}

export interface ILeaguesTableRow {
    id: string,
    rowType: "week" | "summary",
    event: string,
    active?: boolean,
    stats: {
        games: number | null
        winRate: number | null
        rating: number | null,
        rank: number | null,
        wins: number | null,
        trophies: number | null
    }
}

export interface ISeasonStatsBox {
    highestRank: number | null,
    winRate: number | null,
    totalGamesPlayed: number | null,
    highestRating: number | null,
    wins: number | null,
    nickname: string,
    trophies: number | null,
    tournamentInfo: {
        subscribed: boolean,
        key: string,
        name: string,
        ended: boolean
    }
}