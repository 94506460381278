/**
 * Returns a human-readable string representing the time difference
 * between a given timestamp and the current time.
 * @param {number} timestamp - The timestamp to compare with the current time
 * @returns {string} A human-readable string representing the time difference
 *
 * The function compares the given timestamp with the current time and
 * calculates the time difference in days, hours, and minutes. The function
 * then returns a string that describes the time difference in human-readable
 * terms.
 *
 * The function returns one of the following strings:
 * - "More than a year ago." if the time difference is one year or more
 * - "X days ago." if the time difference is more than one day
 * - "X hours ago." if the time difference is less than one day but more than one hour
 * - "X minutes ago." if the time difference is less than one hour but more than one minute
 * - "Less than a minute ago." if the time difference is less than one minute
 *
 * @example
 * const timestamp = Date.now() - 10000;
 * const timeDiff = getHumanTimeDifference(timestamp); // "Less than a minute ago."
 */
export const getHumanTimeDifference = (timestamp: number) => {
  if (timestamp == 0) {
    return '';
  }

  timestamp = addMillisecondsToTimestamp(timestamp);

  const targetDate = new Date(timestamp)
  const diff = new Date().getTime() - targetDate.getTime();
  const diffDays = Math.floor(diff / 86400000); // days
  const diffHrs = Math.floor((diff % 86400000) / 3600000); // hours
  const diffMins = Math.round(((diff % 86400000) % 3600000) / 60000); // minutes

  if (diffDays >= 365) {
    return "More than a year ago.";
  }

  if (diffDays > 1) {
    return `${diffDays} day${diffDays > 1 ? 's' : ''} ago.`;
  }

  if (diffHrs > 0) {
    return `${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago.`;
  }

  if (diffMins > 1) {
    return `${diffMins} minute${diffMins > 1 ? 's' : ''} ago.`;
  }

  return `Less than a minute ago.`;
}

/**
 * Returns a human-readable string representing the time countdown between
 * a given start date and end date.
 * @param {Date} startDate - The start date
 * @param {Date} endDate - The end date
 * @returns {string} A human-readable string representing the time countdown
 *
 * The function calculates the time difference between the given start and end
 * dates and formats the time difference in days, hours, minutes, and seconds.
 * The function returns a string that describes the time countdown in
 * human-readable terms.
 *
 * The function returns a string with one or more of the following sections:
 * - "X days" if the time difference is one day or more
 * - "X hours" if the time difference is less than one day but more than one hour
 * - "X minutes" if the time difference is less than one hour but more than one minute
 * - "X seconds" if the time difference is less than one minute
 *
 * The function uses the plural form of each time unit when appropriate (e.g.
 * "2 days" instead of "2 day").
 *
 * @example
 * const startDate = new Date();
 * const endDate = new Date(Date.now() + 10000);
 * const countdown = getHumanTimeCountdown(startDate, endDate); // "10 seconds"
 */
export function getHumanTimeCountdown(startDate: Date, endDate: Date): string {
  const timeDiffMs = endDate.getTime() - startDate.getTime();

  if (timeDiffMs < 0 || isNaN(timeDiffMs)) {
    return "0 seconds";
  }

  const seconds = Math.floor(timeDiffMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const remainingHours = hours % 24;
  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  let countdown = '';

  if (days > 0) {
    countdown += `${days} day${days > 1 ? 's' : ''}, `;
    if (remainingHours > 0) {
      countdown += `${remainingHours} hour${remainingHours > 1 ? 's' : ''}`;
    }
    return countdown;
  }

  if (remainingHours > 0) {
    countdown += `${remainingHours} hour${remainingHours > 1 ? 's' : ''}, `;
    if (remainingMinutes > 0) {
      countdown += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
    }
    return countdown;
  }


  if (remainingMinutes > 0) {
    countdown += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}, `;
  }

  countdown += `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;

  return countdown;
}

/**
 * Adds milliseconds to a timestamp if it is 10 digits long.
 * @param {number} timestamp - The timestamp to add milliseconds to
 * @returns {number} The modified timestamp with milliseconds added (if necessary)
 *
 * The function checks if the given timestamp is 10 digits long. If it is, the
 * function appends three zeroes to the end of the timestamp to represent
 * milliseconds. If the timestamp is not 10 digits long, the function returns
 * the original timestamp.
 *
 * This function is useful when working with timestamps that do not include
 * milliseconds, as some JavaScript functions require timestamps with
 * milliseconds included.
 *
 * @example
 * const timestamp = 1648010138;
 * const newTimestamp = addMillisecondsToTimestamp(timestamp); // 1648010138000
 */
export const addMillisecondsToTimestamp = (timestamp?: number) => {
  if (!timestamp) {
    return 0;
  }
  if (`${timestamp}`.length == 10) {
    timestamp = +`${timestamp}000`;
  }

  return timestamp;
}

/**
 * Returns a short date string in the format "MMM DD, YYYY" for a given timestamp.
 * @param {number} timestamp - The timestamp to format
 * @returns {string} A short date string in the format "MMM DD, YYYY"
 *
 * The function creates a new Date object from the given timestamp and formats
 * the date using the options `{ year: 'numeric', month: 'short', day: '2-digit' }`.
 * The resulting string has the format "MMM DD, YYYY", where:
 * - "MMM" is the abbreviated month name (e.g. "Jan", "Feb", "Mar")
 * - "DD" is the zero-padded day of the month (e.g. "01", "02", "03")
 * - "YYYY" is the full year (e.g. "2021", "2022", "2023")
 *
 * The function assumes that the timestamp is in milliseconds. If the timestamp
 * is not in milliseconds, the function may not return the correct date.
 *
 * @example
 * const timestamp = Date.now();
 * const shortDate = getShortDate(timestamp); // "Apr 17, 2023"
 */
export function getShortDate(timestamp: number): string {
  const date = new Date(addMillisecondsToTimestamp(timestamp));
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  return date.toLocaleDateString('en-US', options as any);
}

/**
 * Returns a short date string in the format "MMM DD" for a given timestamp.
 * @param {number} timestamp - The timestamp to format
 * @returns {string} A short date string in the format "MMM DD"
 *
 * The function creates a new Date object from the given timestamp and formats
 * the date using the options `{ month: 'short', day: '2-digit' }`.
 * The resulting string has the format "MMM DD", where:
 * - "MMM" is the abbreviated month name (e.g. "Jan", "Feb", "Mar")
 * - "DD" is the zero-padded day of the month (e.g. "01", "02", "03")
 *
 * The function assumes that the timestamp is in milliseconds. If the timestamp
 * is not in milliseconds, the function may not return the correct date.
 *
 * @example
 * const timestamp = Date.now();
 * const shortDate = getShortDateNoYear(timestamp); // "Apr 17"
 */
export function getShortDateNoYear(timestamp: number): string {
  const date = new Date(addMillisecondsToTimestamp(timestamp));
  const options = { month: 'short', day: '2-digit' };
  return date.toLocaleDateString('en-US', options as any);
}

/**
 * Converts a given timestamp in milliseconds to a formatted date string.
 *
 * @param timestamp - The timestamp to convert, in milliseconds.
 * @returns The formatted date string in the format of "MMM DD, YYYY, h:mm:ss AM/PM".
 * If the timestamp is falsy, an empty string will be returned.
 */
export function getTimestampDateFormatted(timestamp: number): string {
  if (!timestamp) {
    return "";
  }
  const date = new Date(addMillisecondsToTimestamp(timestamp));
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  return date.toLocaleTimeString('en-US', options as any);
}

/**
 * Converts a given timestamp in milliseconds to a formatted date string without seconds.
 *
 * @param timestamp - The timestamp to convert, in milliseconds.
 * @returns The formatted date string in the format of "MMM DD, YYYY, h:mm AM/PM".
 * If the timestamp is falsy, an empty string will be returned.
 */
export function getTimestampDateFormattedNoSeconds(timestamp?: number): string {
  if (!timestamp) {
    return "";
  }

  const date = new Date(addMillisecondsToTimestamp(timestamp));
  const options = { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleTimeString('en-US', options as any);
}

/**
 * Converts a given timestamp in milliseconds to a formatted date string without the year.
 *
 * @param timestamp - The timestamp to convert, in milliseconds.
 * @returns The formatted date string in the format of "Month DD" without the year.
 * If the timestamp is falsy, an empty string will be returned.
 */
export function getTimestampDateFormattedNoYear(timestamp?: number): string {
  if (!timestamp) {
    return "";
  }

  const date = new Date(addMillisecondsToTimestamp(timestamp));
  const options = { month: 'long', day: '2-digit' };
  return date.toLocaleTimeString('en-US', options as any);
}

/**
 * Converts a given timestamp in milliseconds to a formatted date string without the year and seconds.
 *
 * @param timestamp - The timestamp to convert, in milliseconds.
 * @returns The formatted date string in the format of "Month DD, h:mm AM/PM" without the year and seconds.
 * If the timestamp is falsy, an empty string will be returned.
 */
export function getTimestampDateFormattedNoYearNoSeconds(timestamp?: number): string {
  if (!timestamp) {
    return "";
  }
  const date = new Date(addMillisecondsToTimestamp(timestamp));
  const options = { month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleTimeString('en-US', options as any);
}