import React, { useEffect, useState } from "react";
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import jwt_decode from "jwt-decode";
import { getIdentity } from "../../commands/user/identity";
import { IdentityResult } from "../../commands/APIResult";
import { useRouter } from "next/router";
import { fireBaseAnalyticTBUid } from "../../helpers/analytic/firebase/FireBaseAnalyticTBUid";
import { API, graphqlOperation } from "@aws-amplify/api";
import * as subscriptions from "../../commands/graphql/messages/subscriptions";
import { INotification } from "../../helpers/interfaces/notification";
import { useNotificationsStore } from "../../stores/notifications/notificationsStore";
import { error } from "../../helpers/utils/log";

API.configure({
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": `${process.env.NEXT_PUBLIC_FEED_API}/graphql`,
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_LAMBDA"
})

export const DOMAIN = process.env.NEXT_PUBLIC_DOMAIN_URL || 'eternaldragons.com';

export function setLinkEmailIdToken(idToken: string) {
    if(idToken) {
        setCookie(
            "linkEmailPasswordIdToken",
            idToken,
            {
                maxAge: 1 * 60 * 60,
                path: "/",
                domain: DOMAIN,
                secure: true,
            });
    }
}

export function getLinkEmailIdToken() {
    let idTokenStr = getCookie("linkEmailPasswordIdToken", { domain: DOMAIN });
    return idTokenStr as string;
}

export function clearLinkEmailIdToken() {
    deleteCookie("linkEmailPasswordIdToken", { domain: DOMAIN });
}

export interface SessionContextInterface {
    session: string | null | undefined | boolean;
    setSession: (session: string) => void;
    hasSession: () => boolean;
    getTBUid: () => string | undefined;
    tbUid: string;
    clearSession: () => any;
    setLinkEmailIdToken: (isToken: string) => void;
    getLinkEmailIdToken: () => string | undefined;
    clearLinkEmailIdToken: () => any;
    identity: Partial<IdentityResult>;
    reloadIdentity: Function;
}

export const SessionContext = React.createContext<SessionContextInterface | null>(null);

export interface props {
    session: string;
    children: any
}

export const SessionProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [ session, setSession ] = useState(getSession());
    const [ data, setData ] = useState<Partial<IdentityResult>>({});
    const {
        addNotification
    } = useNotificationsStore();

    useEffect(() => {
        if (hasSession(session)) {

            let tbUid = getTBUid(session);

            if (tbUid)
                fireBaseAnalyticTBUid.setUserAddress(tbUid);

            reloadIdentity();
        }

    }, [ session ]);

    useEffect(() => {
        if (hasSession(session)) {
            let tbUid = getTBUid(session);
            // @ts-ignore
            const subscription = API.graphql<any>(graphqlOperation(subscriptions.onAddMessage, { tbUid }, session as string)).subscribe({
                next: ({ provider, value }: { provider: any, value: { data: { onAddMessage: INotification } } }) => {
                    addNotification(value.data.onAddMessage);
                },
                error: (error: any) => {
                    console.warn("Error on listening for new message", error);
                }
            });

            return () => {
                subscription.unsubscribe();
            }
        }
    }, [ session ]);

    const hasSession = (session: string | null | undefined | boolean) => {
        return session !== undefined
    }

    const getTBUid = (session: string | null | undefined | boolean): string | undefined => {
        if (session) {
            let sessionparse = parseToken(session as string);
            return sessionparse.tbUid;
        }
    }

    const parseToken = (token: string): any => {

        let decoded = jwt_decode(token);

        //console.log("DECODED", decoded);

        return decoded;
    }

    const clearSession = (): any => {
        deleteCookie("token", { domain: DOMAIN });
        deleteCookie("playerData", { domain: DOMAIN });
        deleteCookie("userData", { domain: DOMAIN });

        setSession(undefined);
    }

    const reloadIdentity = async () => {
        try {
            const result = await getIdentity(session as string);
            setData(result);
        } catch (e) {
            error("SessionContext", "Error reloading identity:", e);
        }
    }

    return (
        <SessionContext.Provider value={{
            identity: data,
            session,
            setSession,
            hasSession: () => {
                return hasSession(session)
            },
            getTBUid: () => {
                return getTBUid(session)
            },
            tbUid: getTBUid(session) || "",
            clearSession,
            reloadIdentity,
            setLinkEmailIdToken,
            getLinkEmailIdToken,
            clearLinkEmailIdToken
        }}>
            {children}
        </SessionContext.Provider>
    )
}

export const useSession = () => React.useContext(SessionContext);

const parseToken = (token: string): any => {

    let decoded = jwt_decode(token);

    //   console.log("DECODED", decoded);

    return decoded;
}

export function getTBUid() {

    let session = getSession();

    if (session) {
        let sessionparse = parseToken(session as string);
        return sessionparse.tbUid;
    }

    return undefined;
}


export function getSession() {

    try {
        let token = getCookie("token", { domain: DOMAIN });

        // console.log("TOKEN", token)
        if (token != undefined) {
            let session = parseToken(token as string);

            //   console.log("SESSION", session)
            return token;

        }

        return undefined;


    } catch (_b) {
        // This catch block handles the known issues listed here: https://caniuse.com/#feat=namevalue-storage
        console.warn(
            "useSession could not access the browser storage. Session will be lost when closing browser window"
        );
    }
    return null;
}

const clear = () => {
    deleteCookie("token", { domain: DOMAIN });
    deleteCookie("playerData", { domain: DOMAIN });
    deleteCookie("userData");
    deleteCookie("linkEmailPasswordIdToken", { domain: DOMAIN });
}

export const loadUserData = async (token: string) => {
    const data: IdentityResult = await getIdentity(token);

    if (!data) {
        deleteCookie("userData", { domain: DOMAIN });
        return;
    }

    const avatarUrl = `${process.env.NEXT_PUBLIC_CDN_URL}/avatar/${data.tbUid}`;
    const userData = JSON.stringify({ nickName: data.nickname, tbUid: data.tbUid, avatarUrl });

    setCookie(
        "userData",
        userData,
        {
            maxAge: 24 * 60 * 60,
            path: "/",
            domain: DOMAIN,
            secure: true
        });
}

export const updateToken = async (token: string) => {
    if (token) {
        setCookie(
            "token",
            token,
            {
                maxAge: 24 * 60 * 60,
                path: "/",
                domain: DOMAIN,
                secure: true
            });

        let session = parseToken(token);

        // refresh user cookies
        await loadUserData(token);

        return session;
    }
};

export interface IWithAuthProps {
    children?: React.ReactNode;
    session: string | null | undefined | boolean;
    tbUid: string | undefined;
    identity: Partial<IdentityResult>;
}

// https://react-typescript-cheatsheet.netlify.app/docs/hoc/full_example/

export function withAuth<T extends IWithAuthProps = IWithAuthProps>(Component: React.ComponentType<T>) {
    const WithAuthComponent = (props: Omit<T, keyof IWithAuthProps>) => {
        const { hasSession, getTBUid, session, identity } = React.useContext(SessionContext) as SessionContextInterface;
        const router = useRouter();

        if (!hasSession()) {
            console.log("No session, routing to login.")

            // Save loginRedirect url so we can get return to that page after user login!
            setCookie(
                "loginRedirect",
                router.asPath,
                {
                    path: "/",
                    domain: process.env.NEXT_PUBLIC_DOMAIN_URL,
                    secure: true
                });

            router.push("/login");

            return null;
        }

        const authProps: IWithAuthProps = { tbUid: getTBUid(), session, identity }

        return <Component {...authProps} {...(props as T)} />;
    };

    return WithAuthComponent;
}

