import * as React from "react";
import { memo, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import Stack from "@mui/material/Stack";
import { edWebkitTheme, grey } from "@trailblazer-game/ed-webkit";
import { useRouter } from "next/router";
import {
  SessionContext,
  SessionContextInterface,
} from "../../hooks/SessionHook/SessionContext";
import { EPage } from "../../helpers/constants";
import Footer_NotionLinks from "./Footer_NotionLinks";
import { NotionMenuGroup, NotionSlug } from "../../helpers/notion/notion";

interface IFooterProps {
  className?: string;
}

// Component
const Footer = memo((props: IFooterProps) => {
  const theme = useTheme();
  const router = useRouter();
  const [slugs, setSlugs] = useState<NotionSlug[]>([]);
  const { t } = useTranslation();
  const { hasSession } = React.useContext(
    SessionContext
  ) as SessionContextInterface;
  const bHasSession = hasSession();
  const desktop = useMediaQuery(theme.breakpoints.up(800));
  const byMenuOrder = (a: NotionSlug, b: NotionSlug) =>
    a.menu_order - b.menu_order;
  const getNotionLinksByGroup = (menuGroup: NotionMenuGroup) =>
    slugs
      .filter((s) => s.menu_group == menuGroup && s.show_in_footer)
      .sort(byMenuOrder);

  useEffect(() => {
    async function fetchSlugs() {
      const response = await fetch("/slugs.json");
      const jsonData = await response.json();
      setSlugs(jsonData);
    }

    fetchSlugs();
  }, []);

  return (
    <Box className={props.className} id="appfooter">
      <Stack
        direction={desktop ? "row" : "column"}
        spacing={6}
        justifyContent={"space-between"}
      >
        <Stack direction={desktop ? "row" : "column"} spacing={6}>
          <Stack spacing={2}>
            <Link href={"/"} passHref>
              <a>
                <Box className={"link"}>{t("menu.home")}</Box>
              </a>
            </Link>

            <Link href={`/${EPage.COMMUNITY}`} passHref>
              <a>
                <Box className={"link"}>{t("menu.community")}</Box>
              </a>
            </Link>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://magiceden.io/marketplace/eternal_dragons_genesis"
            >
              <Box className={"link"}>{t("menu.marketplace")}</Box>
            </a>
            <Link href={`/${EPage.NEWS}`} passHref>
              <a>
                <Box className={"link"}>{t("menu.news")}</Box>
              </a>
            </Link>
          </Stack>
          <Stack spacing={2}>
            <Link href="/ranking" passHref>
              <a>
                <Box
                  className={
                    router.pathname == "/ranking" ? "link active" : "link"
                  }
                >
                  {t("menu.ranking")}
                </Box>
              </a>
            </Link>

            {bHasSession && (
              <>
                <Link href="/mygallery" passHref>
                  <a>
                    <Box
                      className={
                        router.pathname == "/mygallery" ? "link active" : "link"
                      }
                    >
                      {t("menu.gallery")}
                    </Box>
                  </a>
                </Link>
              </>
            )}

            {bHasSession && (
              <Link href={"/profile"} passHref>
                <a>
                  <Box
                    className={
                      router.pathname == "/profile" ? "link active" : "link"
                    }
                  >
                    {t(`menu.profile`)}
                  </Box>
                </a>
              </Link>
            )}

            {!bHasSession && (
              <Link href={"/login"} passHref>
                <a>
                  <Box
                    className={
                      router.pathname == "/login" ? "link active" : "link"
                    }
                  >
                    {t(`menu.login`)}
                  </Box>
                </a>
              </Link>
            )}
          </Stack>

          <Stack spacing={2}>
            <Link
              href={"https://www.trailblazer.games/terms-of-service"}
              passHref
            >
              <a>
                <Box
                  className={
                    router.pathname ==
                    "https://www.trailblazer.games/terms-of-service"
                      ? "link active"
                      : "link"
                  }
                >
                  Terms of Service
                </Box>
              </a>
            </Link>
            <Link href={"/buyback-terms"} passHref>
              <a>
                <Box
                  className={
                    router.pathname == "/buyback-terms" ? "link active" : "link"
                  }
                >
                  BuyBack T&CS
                </Box>
              </a>
            </Link>
          </Stack>

          <Footer_NotionLinks slugs={getNotionLinksByGroup("Others")} />
          <Footer_NotionLinks slugs={getNotionLinksByGroup("Developer")} />
        </Stack>
        <a
          rel="noreferrer"
          target="_blank"
          href={"https://www.trailblazer.games/"}
          className={"tb-logo"}
        >
          <img alt="Trailblazer Logo White" src="/tb_logo.png" />
        </a>
      </Stack>
    </Box>
  );
});

Footer.displayName = "Footer";

// Default style!
const FooterStyled = styled(Footer)({
  padding: edWebkitTheme.spacing(12, 6, 12, 6),
  position: "relative",
  maxWidth: "1920px",
  backgroundColor: grey("1000"),
  "span, p": {
    fontFamily: "Figtree",
  },
  ["@media (max-width:1000px)"]: {
    padding: edWebkitTheme.spacing(6, 2, 12, 2),
  },
  ".link": {
    textTransform: "uppercase",
    color: edWebkitTheme.palette.grey["50"],
    fontWeight: "400",
    cursor: "pointer",
    ...edWebkitTheme.typography.body2,
    "&.active": {
      color: "#ffea9a",
    },
    ["@media (max-width:800px)"]: {
      fontSize: "0.75rem",
    },
  },
  ".tb-logo": {
    img: {
      width: "220px",
      height: "64px",
      ["@media (max-width:800px)"]: {
        width: "137px",
        height: "40px",
      },
    },
  },
});

// Export
export default FooterStyled;
