import React, { memo, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useTwitchStore } from "./twitchStore";
import Timeout = NodeJS.Timeout;

const AFTER_STREAMING_UPDATE_INTERVAL = 60 * 1000 * 5; // 5 minutes
const STREAMING_UPDATE_INTERVAL = 60 * 1000; // 1 minute
const OFFLINE_UPDATE_INTERVAL = 30 * 1000; // 30 seconds

const TwitchStoreController = memo(() => {
    const { streaming, wasStreaming, loadUserData, loadStreamData } = useTwitchStore();

    useEffect(() => {
        let interval: Timeout;

        if (wasStreaming) {
            // if the user was streaming, keep the chat for 5 minutes
            interval = setInterval(() => loadStreamData(), AFTER_STREAMING_UPDATE_INTERVAL);
        } else {
            // else refresh the stream data each minute or 30s depending on current user state
            interval = setInterval(() => loadStreamData(), streaming ? STREAMING_UPDATE_INTERVAL : OFFLINE_UPDATE_INTERVAL);
        }

        return () => clearInterval(interval);
    }, [ streaming, wasStreaming ]);

    useEffect(() => {
        initialLoad();
    }, []);

    const initialLoad = async () => {
        await loadUserData();
        await loadStreamData();
    }

    return <></>
});

TwitchStoreController.displayName = "TwitchStoreController";

// Default style!
const TwitchStoreControllerStyled = styled(TwitchStoreController)({});

// Export
export default TwitchStoreControllerStyled;
