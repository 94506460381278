import axios from 'axios';
import { APILoginResult, APIResult, IdentityResult } from '../APIResult';



export type LoginResult =  {
    provider:string;
    providerId:string;
    tbUid:string;
    metadata: any;
}

//Return all the logins this user is link to
export async function getLogins(token: string, tbuid?:string): Promise<LoginResult[] | null> {

    try {

        let res = await axios.get(`${process.env.NEXT_PUBLIC_GATE}/logins`,
            {
                headers: {
                    Authorization: token
                }
            });

        console.log("Get login", res)
        if (res.data) {
            // created
            return res.data
        }

        return [];

    } catch (error: any) {

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log('Error', error.response.data);
            console.log('Error', error.response.status);
            console.log('Error', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log('Error', error.config);

        return null;
    };


}