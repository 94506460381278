import React, { useEffect, useReducer, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { IUserDataContextInterface, UserDataContext } from "../UserDataHook/UserDataContext";
import { EDLoader, EDOperationPopup } from "@trailblazer-game/ed-webkit";
import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";
import WalletAdapterContext_LinkWarning from "./WalletAdapterContext_LinkWarning";

export interface IWalletAdapterContextInterface {
    children?: React.ReactNode;
    canSignWith: () => ESignResult;
    connect: Function;
    closeLinkedDialog: Function;
    notLinkedDialog: { open: boolean, walletAddress: string };
    connected: boolean;
    publicKey: string | undefined;
}

interface IWalletAdapterContextState {
    notLinkedDialog: { open: boolean, walletAddress: string }
}

const initialState: IWalletAdapterContextState = {
    notLinkedDialog: { open: false, walletAddress: "" }
}

enum EWalletAdapterContextActions {
    OpenNotLinkedDialog,
    CloseNotLinkedDialog
}

interface IWalletAdapterContextAction {
    type: EWalletAdapterContextActions;
    notLinkedDialog?: { open: boolean, walletAddress: string }
}

const walletAdapterContextCreateReducer = (state: IWalletAdapterContextState, action: IWalletAdapterContextAction): IWalletAdapterContextState => {
    switch (action.type) {
        case EWalletAdapterContextActions.OpenNotLinkedDialog:
            return {
                ...state,
                notLinkedDialog: action.notLinkedDialog!
            }
        case EWalletAdapterContextActions.CloseNotLinkedDialog:
            return {
                ...state,
                notLinkedDialog: initialState.notLinkedDialog
            }
        default:
            return state;
    }
}


export const WalletAdapterContext = React.createContext<IWalletAdapterContextInterface | null>(null);

export const WalletAdapterProvider: React.FC<React.ReactNode> = ({ children }) => {
    const { publicKey, connected,  } = useWallet();
    const { setVisible: setWalletModalVisible, visible: isWalletModalVisible } = useWalletModal();
    const { userData, dataLoaded } = React.useContext(UserDataContext) as IUserDataContextInterface;
    const [ state, dispatch ] = useReducer(walletAdapterContextCreateReducer, initialState);

    useEffect(() => {
        console.log('Wallet changed [%o]', publicKey?.toBase58() || " UNDEFINED ");
        if (dataLoaded) {
            checkIfCanSign();
        }
    }, [ publicKey, connected, userData ]);

    const checkIfCanSign = () => {
        // force user to connect
        if (!connected) {
            dispatch({ type: EWalletAdapterContextActions.CloseNotLinkedDialog });
            return ESignResult.NotConnected;
        }

        if (!publicKey) {
            dispatch({ type: EWalletAdapterContextActions.CloseNotLinkedDialog });
            return ESignResult.NoPublicKey;
        }

        const walletAddress = publicKey.toBase58();

        // check if current connected wallet is linked or not
        if (!userData.hasLinkedWalletWithPublicKey(walletAddress)) {
            dispatch({ type: EWalletAdapterContextActions.OpenNotLinkedDialog, notLinkedDialog: { open: true, walletAddress } });
            return ESignResult.WrongWallet;
        }

        dispatch({ type: EWalletAdapterContextActions.CloseNotLinkedDialog });
        return ESignResult.Success;
    }

    const connect = () => {
        setWalletModalVisible(true);
    }

    const handleNotLinkedDialogClose = () => {
        dispatch({ type: EWalletAdapterContextActions.CloseNotLinkedDialog });
    }

    return (
        <WalletAdapterContext.Provider value={{
            notLinkedDialog: state.notLinkedDialog,
            connected: connected,
            publicKey: publicKey?.toBase58(),
            canSignWith: checkIfCanSign,
            connect: connect,
            closeLinkedDialog: handleNotLinkedDialogClose
        }}>
            {children}
        </WalletAdapterContext.Provider>
    );
}


export enum ESignResult {
    NotConnected,
    WrongWallet,
    Success,
    NotLinked,
    NoPublicKey,
}

export interface IWithWalletAdapterProps extends IWalletAdapterContextInterface {
}

// https://react-typescript-cheatsheet.netlify.app/docs/hoc/full_example/

export function withWalletAdapter<T extends IWithWalletAdapterProps = IWithWalletAdapterProps>(Component: React.ComponentType<T>, header: string, body: string) {
    const WithWalletAdapterComponent = (props: Omit<T, keyof IWithWalletAdapterProps>) => {
        return <WalletAdapterContext.Consumer>
            {(state) => {
                if(state?.notLinkedDialog.open) {
                    return <EDOperationPopup
                        in={true}
                        header={"Wallet not linked"}>
                        <WalletAdapterContext_LinkWarning />
                    </EDOperationPopup>;
                }

                return (state?.connected && state.publicKey)
                    ? <Component {...(props as T)} canSignWith={state.canSignWith} connect={state.connect}/>
                    : <ConnectWallet header={header || "Connect your wallet"}
                                     body={body || "Connect your wallet to our website to continue."}/>
            }}
        </WalletAdapterContext.Consumer>
    };

    return WithWalletAdapterComponent;
}