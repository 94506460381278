export enum SuperContentNameToSymbol {
    "descendantEggs" = "EDGE",
    "Genesis" = "EDG",
    "ticketsPlayerCards" = "EDT",
    "sagaCards" = "WPC",
    "Minion" = "EDM",
    "Descendant" = "EDD",
    "Trophy" = "EDTY",
    "Medal" = "EDML",
}

export enum SuperContentSymbolToName {
    "EDGE" = "descendantEggs",
    "EDG" = "Genesis",
    "EDT" = "ticketsPlayerCards",
    "WPC" = "sagaCards",
    "EDM" = "Minion",
    "EDD" = "Descendant",
    "EDTY" = "Trophy",
    "EDML" = "Medal"
}

export type SuperContentSymbols = "EDGE" | "EDG" | "EDT" | "WPC" | "EDM" | "EDD" | "EDTY" | "EDML"

export interface ISuperContent {
    id: string;
    owner: string;
    updatedAt: number;
    uri: string;
    name: string;
    image: string;
    imageSm: string;
    count: number;
    collection: { id: string };
    symbol: SuperContentSymbols;
    isMintable: boolean;
    isTradable: boolean;
    isUpgradable: boolean;
    subTitle?: string;
}