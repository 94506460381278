import React, { useEffect, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ReactCountryFlag from "react-country-flag";
let cookieCutter = require('cookie-cutter');

// Component
function LocaleChanger(props: any) {
    const [localeValue, setLocaleValue] = React.useState('en');
    const { i18n } = useTranslation();

    const handleChange = (event: SelectChangeEvent) => {
        setLocaleValue(event.target.value as string);
        i18n.changeLanguage(event.target.value);
        cookieCutter.set('siteLang', event.target.value);
    };

    useEffect(() => {
        let lang = cookieCutter.get('siteLang');

        if (lang) {
            setTimeout(() => {
                setLocaleValue(lang);
                i18n.changeLanguage(lang);
            }, 0);
        }
    }, [i18n]);


    console.log("SSSSS");
    console.log(props.mystyle)

    return (
        <Select
            sx={props.mystyle}
            variant="standard"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={localeValue}
            onChange={handleChange}>

            <MenuItem value={'en'}><ReactCountryFlag countryCode="US" /></MenuItem>
            {/*<MenuItem value={'fr'}><ReactCountryFlag countryCode="FR" /></MenuItem>*/}
        </Select>
    );
}

// Default style!
const LocaleChangerStyled = styled(LocaleChanger)({

});

// Export
export default LocaleChangerStyled;
