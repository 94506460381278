import React from 'react';
import { styled } from '@mui/material/styles';
import { EDButton, EDPageBackground, EDTypography, edWebkitTheme } from "@trailblazer-game/ed-webkit";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useWalletModal } from "@solana/wallet-adapter-react-ui";

//
function ConnectWallet(props: any) {
    const { setVisible: setWalletModalVisible, visible: isWalletModalVisible } = useWalletModal();

    return (
        <Box className={props.className}>
            <EDPageBackground src={"/ed_wb_dragonridingv6_1.webp"} in={true}/>
            <Stack spacing={2} alignItems={'center'} sx={{ position: 'relative' }}>
                <img src={"/mint/Ornament_Light.png"} style={{ maxWidth: "128px", width: "100%" }}/>

                {props.header && <EDTypography variant={'h2'} align={"center"}>{props.header}</EDTypography>}
                {props.body && <EDTypography variant={'body1'} align={"center"}>{props.body}</EDTypography>}

                <EDButton label={"Connect"} onClick={() => setWalletModalVisible(true)}/>

                {/*<WalletAdapter/>*/}
            </Stack>
        </Box>
    );
}

// Default style!
const ConnectWalletStyled = styled(ConnectWallet)({
    color: edWebkitTheme.palette.grey['50'],
    zIndex: 1001
});

// Export
export default ConnectWalletStyled;