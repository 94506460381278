import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import { EDTypography, EEDAnimation, grey } from "@trailblazer-game/ed-webkit";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { theme } from "../Theme/Theme";

interface IAppBarDropdownMenuProps {
  className?: string;
  label: string;
  children: React.ReactNode | React.ReactNode[];
}

function AppBarDropdownMenu({ label, children, className }: IAppBarDropdownMenuProps) {
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOnMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <Box onMouseLeave={handleClose} onMouseEnter={handleOnMouseEnter} className={className}>
    <EDTypography variant={"button"} className={"link"}>{label}</EDTypography>
    {open && <Stack className={`sub-menu card-shadow ${EEDAnimation.FadeIn}`} alignItems={"stretch"} justifyContent={"flex-start"} spacing={2}>
      {children}
    </Stack>}
  </Box>
}

const AppBarDropdownMenuStyled = styled(AppBarDropdownMenu)({
  position: "relative",
  ".sub-menu": {
    position: "absolute",
    left: 0,
    top: "100%",
    backgroundColor: grey(900),
    padding: theme.spacing(3, 2),
    borderRadius: "8px",
    flexGrow: 1,
    maxWidth: "500px",
    animationDuration: "0.25s",
    ".link": {
      justifyContent: "flex-start",
      padding: 0,
      whiteSpace: "nowrap"
    }
  }
});

export default AppBarDropdownMenuStyled;