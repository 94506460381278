import React, { useReducer } from "react";
import { styled } from '@mui/material/styles';
import WalletAdapterContext_LinkWarning_Idle from "./WalletAdapterContext_LinkWarning_Idle";
import WalletAdapterContext_LinkWarning_Linking from "./WalletAdapterContext_LinkWarning_Linking";

interface IWalletAdapterContextLinkWarningProps {
    className?: string;
}

enum EWalletAdapterContextLinkWarningPhase {
    Idle,
    Linking
}

interface IWalletAdapterContextLinkWarningState {
    phase: EWalletAdapterContextLinkWarningPhase;
    loading: boolean;
}

const initialState: IWalletAdapterContextLinkWarningState = {
    phase: EWalletAdapterContextLinkWarningPhase.Idle,
    loading: false
}

enum EWalletAdapterContextLinkWarningActions {
    Link,
    Idle
}

interface IWalletAdapterContextLinkWarningAction {
    type: EWalletAdapterContextLinkWarningActions;
}

const WalletAdapterContextLinkWarningReducer = (state: IWalletAdapterContextLinkWarningState, action: IWalletAdapterContextLinkWarningAction): IWalletAdapterContextLinkWarningState => {
    switch (action.type) {
        case EWalletAdapterContextLinkWarningActions.Link:
            return {
                ...initialState,
                phase: EWalletAdapterContextLinkWarningPhase.Linking
            }
        case EWalletAdapterContextLinkWarningActions.Idle:
            return initialState;
        default:
            return state;
    }
}

//
function WalletAdapterContextLinkWarning(props: IWalletAdapterContextLinkWarningProps) {
    const [ state, dispatch ] = useReducer(WalletAdapterContextLinkWarningReducer, initialState);

    const handleProceed = () => {
        dispatch({ type: EWalletAdapterContextLinkWarningActions.Link })
    }

    const handleClose = () => {
        dispatch({ type: EWalletAdapterContextLinkWarningActions.Idle })
    }

    const renderByPhase = () => {
        switch (state.phase) {
            case EWalletAdapterContextLinkWarningPhase.Idle:
                return <WalletAdapterContext_LinkWarning_Idle onProceed={handleProceed}/>
            case EWalletAdapterContextLinkWarningPhase.Linking:
                return <WalletAdapterContext_LinkWarning_Linking onClose={handleClose}/>
            default:
                break;

        }
    }

    return <>
        {renderByPhase()}
    </>
}

const WalletAdapterContextLinkWarningStyled = styled(WalletAdapterContextLinkWarning)(() => ({
    position: 'relative'
}));

// Export
export default WalletAdapterContextLinkWarningStyled;